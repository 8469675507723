import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'app-plugin-form',
  templateUrl: './plugin-form.component.html',
  styleUrls: ['./plugin-form.component.css'],
})
export class PluginFormComponent {
  pluginName: string = '';
  pluginCategory: string = '';
  pluginDescription: string = '';
  websiteUrl: string = '';
  videoUrl: string = '';
  icon: File | null = null;
  yamlfile :string = '';
  manifestfile :string = '';
  selectedFile: File | undefined;
  summary: string = '';
  successMessage: string | null = null;

  constructor(private http: HttpClient,private api:ApiService) {}

  onIconSelected(event: any) {
    this.icon = event.target.files[0];
  }

  onSubmit() {
    if (!this.selectedFile) {
      console.error('Please select a file to upload.');
      return;
    }
  
    const formData = new FormData();
    formData.append('pluginName', this.pluginName);
    formData.append('pluginCategory', this.pluginCategory);
    formData.append('pluginDescription', this.pluginDescription);
    formData.append('websiteUrl', this.websiteUrl);
    formData.append('yamlfile', this.yamlfile);
    formData.append('manifestfile', this.manifestfile);
    formData.append('videoUrl', this.videoUrl);
    formData.append('summary', this.summary);
    formData.append('selectedFile', this.selectedFile);
  
    this.api.post('submit', formData).subscribe(
      (response:any) => {
        this.successMessage = response.message;
      },
      (error) => {
        console.error('Error submitting form:', error);
      }
    );
  }
  

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.selectedFile = inputElement.files[0];
    }
  }

}

