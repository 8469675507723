
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="" content="" />
    <title>Market Place</title>

    <link
      rel="stylesheet"
      as="style"
      onload="this.onload=null;this.rel='stylesheet'"
      href="https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css"
    />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="" />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800;900&amp;family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,500&amp;display=swap"
      rel="stylesheet"
    />
    <!-- js files -->
    <script src="src/js/jquery.min.3.2.js"></script>
    <script src="src/js/bootstrap.bundle.min.js"></script>

    <!-- css files -->
    <link rel="stylesheet" type="text/css" href="/Users/ramjilal/Desktop/Weather-Plugin/Plugin Market/src/css/style.css">
    <link rel="stylesheet" type="text/css" as="style" href="/Users/ramjilal/Desktop/Weather-Plugin/Plugin Market/src/css/style.css" all="media">
  </head>
  <body class="bg-light-color">
    <div class="wrapper">
      <!-- Header Start -->
      <header class="site-header fixed-top">
        <nav class="navbar navbar-expand-xl bg-white py-3 py-lg-0 shadow-sm">
          <div class="container">
            <a
              class="navbar-brand"
              href="#"
              aria-label="Market Place"
              title="Market Place"
            >
            <img [src]="'assets/Images/appylogo.png'" alt="Market Place" width="160" height="40" />
            </a>

            <div class="collapse navbar-collapse" id="navbarNavDropdown">
              <div
                class="d-flex align-items-center justify-content-between g-3 mb-3"
              >
                <a
                  class="navbar-brand d-lg-none"
                  href="#"
                  aria-label="Market Place"
                  title="Market Place"
                >
                  <img
                  [src]="'assets/Images/logo.svg'"
                    alt="Market Place"
                    width="160"
                    height="40"
                  />
                </a>
                <button
                  class="btn btn-light text-light closebtn-navbar d-block d-xl-none"
                  type="button"
                >
                  <i class="fa-close fa-lg fa-regular fa-fw"></i>
                </button>
              </div>
              <ul class="navbar-nav mx-auto gap-lg-4">
                <li class="nav-item">
                  <a routerLink="/start" title="Browse" class="nav-link active">Home</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/manage" title="Manage" class="nav-link">Plugins</a>
                </li>
              </ul>
            </div>
            <div class="header-search-wrap d-none d-md-block">
                <ul class="d-flex gap-3">
                    <li class="nav-item">
                        <a href="#" class="nav-link text-black" title="Notification"><i class="fa-solid fa-bell"></i> <span class="live"></span></a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle text-black" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          My Profile
                        </a>
                        <ul class="dropdown-menu">
  <li><a class="dropdown-item" routerLink="/profile">Profile</a></li>
  <li><a class="dropdown-item" (click)="logOut()">Log Out <i class="fa-solid fa-right-from-bracket"></i></a></li>
</ul>

                    </li>
                </ul>
            </div>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
        <!-- /. nav end -->
      </header>
      <!-- /. Header End -->

      <!-- Main Start -->
      <main class="main-body">
        <div class="container">
          <div class="row py-5">
            <div class="col-sm-12">
              <a routerLink="/status" rel="noopener noreferrer"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</a>
              <h2 class="large-headeing mt-3">Extention</h2>
            </div>
          
          </div>
          <section class="bg-white p-2 p-md-5 mb-4">
            <div class="row">
              <div class="col-md-3">
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle dropdown-toggle-split px-4 w-100"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Downloads
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Action 1</a></li>
                  <li><a class="dropdown-item" href="#">Action 2</a></li>
                  <li><a class="dropdown-item" href="#">Action 3</a></li>
                  <li><hr class="dropdown-divider" /></li>
                  <li><a class="dropdown-item" href="#">Separated link</a></li>
                </ul>
              </div>
              <div class="col-md-9 text-end">
                <div
                  class="radio-wrap d-flex justify-content-md-end justify-content-center flex-column flex-md-row mt-2 mt-md-0"
                >
                  <div class="mb-3">
                    <label class="form-check-label btn">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                      Option 1
                    </label>
                  </div>
                  <div class="mb-3">
                    <label class="form-check-label btn">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                      Option 2
                    </label>
                  </div>
                  <div class="mb-3">
                    <label class="form-check-label btn">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                      Option 3
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-9">
                <div class="pic-wrap">
                  <img [src]="'assets/Images/graph-img.png'" class="w-100" alt="" />
                </div>
              </div>
              <div
                class="col-md-3 d-flex align-items-center justify-content-center"
              >
                <div class="g-icon-wrap text-center">
                  <i
                    class="fa fa-cloud-download text-primary"
                    aria-hidden="true"
                  ></i>
                  <p class="mt-2"><strong>10 099</strong></p>
                  <div class="divider my-3"></div>
                  <p>Total Downloads</p>
                </div>
              </div>
            </div>
            <div class="divider my-3 mt-5"></div>

            <div class="row">
              <div class="col-lg-4">
                <div class="left-sec pe-4">
                    <div class="item-wrap">
                        <div class="pic-wrap">
                            <img [src]="'assets/Images/icon-1.png'" alt="" class="w-100">
                        </div>
                        <div class="content-wrap">
                            <h4><strong>Integration</strong></h4>
                            <div class="d-flex">
                                <span class="me-3">2/22/2019</span>
                                <span class="text-primary">
                                    Draft
                                </span>
                            </div>
                            <p>Collaborate with teammates anytime, anywhere.</p>
                            <div class="divider my-3"></div>
                            <ul class="tag-list">
                                <li>{{plugin.pluginCategory}}</li>
                            </ul>
                        </div>
                       </div>
                </div>
              </div>
              <div class="col-lg-8">
                <h4 class="mb-4 mt-3"><strong>App Details</strong></h4>
                <section class="form-wrap bg-white mb-5" *ngIf="plugin">
                    <div class="row mb-4">
                        <div class="col-md-2">
                          <label for="">App Name<span class="required">*</span></label>
                        </div>
                        <div class="col-md-10">
                          <input type="text" class="form-control" [(ngModel)]="plugin.pluginName" />
                        </div>
                      </div>
                  
                  <div class="row mb-4">
                    <div class="col-md-2">
                      <label for="">Category<span class="required">*</span></label>
                    </div>
                    <div class="col-md-10">
                      <div class="row">
                        <div class="col-md-8 mb-4 mb-md-0">
                          <select name="" id="" class="form-select">
                            <option value="">{{ plugin.pluginCategory}}</option>
                          </select>
                        </div>
                        <div class="col-md-4">
                          <button class="btn w-100 btn-outline-primary">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            Add Category
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-2">
                      <label for="">Website URL</label>
                    </div>
                    <div class="col-md-10">
                      <input type="text" class="form-control" [(ngModel)]="plugin.websiteUrl" />
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-2">
                      <label for="">Video URL</label>
                    </div>
                    <div class="col-md-10">
                      <input type="text" class="form-control" [(ngModel)]="plugin.videoUrl"/>
                      <div class="vido-wrap">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/YgZmcsxU45w?si=Lic2DvKnFUzP48NQ"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen=""
                        ></iframe>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-2 mb-2 mb-md-0">
                      <label for="">Icon<span class="required">*</span></label>
                    </div>
                    <div class="col-md-10">
                      <div class="upload-wrapper mb-2">
                        <input type="file" />
                        <div class="content-wrap">
                          <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                          <p>
                            Drag &amp; Drop image here or
                            <span class="text-primary">Browse File</span>
                          </p>
                        </div>
                      </div>

                      <div class="upload-bar">
                        <div class="row">
                          <div class="col-sm-6">
                            <strong>My Attachments</strong>
                          </div>
                          <div class="col-sm-6 text-end">
                            <button class="btn text-primary">
                              <i
                                class="fa fa-cloud-upload"
                                aria-hidden="true"
                              ></i>
                              Browse File
                            </button>
                          </div>
                        </div>

                        <div class="upload-list">
                          <li>
                            <div class="item d-flex justify-content-between">
                              <div class="item-wrap d-flex align-items-start">
                                <div class="pic-wrap">
                                  <img src="./images/icon-1.png" alt="" />
                                </div>
                                <div class="content-wrap">
                                  <h6><strong>Integration</strong></h6>
                                  <p>Productivity</p>
                                </div>
                              </div>
                              <button class="btn btn-close"></button>
                            </div>
                            <div class="progress">
                              <div
                                class="progress-bar bg-success"
                                role="progressbar"
                                style="width: 25%"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </li>
                          <li>
                            <div class="item d-flex justify-content-between">
                              <div class="item-wrap d-flex align-items-start">
                                <div class="pic-wrap">
                                  <img src="./images/icon-1.png" alt="" />
                                </div>
                                <div class="content-wrap">
                                  <h6><strong>Integration</strong></h6>
                                  <p>Productivity</p>
                                </div>
                              </div>
                              <button class="btn btn-close"></button>
                            </div>
                            <div class="progress">
                              <div
                                class="progress-bar bg-success"
                                role="progressbar"
                                style="width: 25%"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-2">
                      <label for="">Description</label>
                    </div>
                    <div class="col-md-10">
                      <textarea
                        class="form-control"
                        [(ngModel)]="plugin.pluginDescription"
                        name=""
                        id=""
                        cols="30"
                        rows="10"

                      ></textarea>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
          <div class="text-end mb-4">
            <button class="btn btn-primary mx-auto fw-bold buynow-btn py-2 px-5">Save Changes</button>
          </div>
        </div>

        <!-- /. Recommended section end -->
      </main>
      <!-- /. Main End -->
      <!-- footer start -->
      <footer class="site-footer bg-white py-5">
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <a href="#" class="mb-4 d-inline-block">
                <img
                [src]="'assets/Images/logo.svg'"
                  alt="Market Place"
                  width="160"
                  height="40"
                />
              </a>
            </div>
            <div class="col-md-2">
              <h3>Browse</h3>
              <ul>
                <li><a href="#">Most Popular</a></li>
                <li><a href="#">Essential Apps</a></li>
              </ul>
            </div>
            <div class="col-md-2">
              <h3>Developers</h3>
              <ul>
                <li><a href="#">Documentation</a></li>
                <li><a href="#">Getting Started </a></li>
                <li><a href="#">API Reference </a></li>
                <li><a href="#">Sample Code</a></li>
              </ul>
            </div>
            <div class="col-md-2">
              <h3>Company</h3>
              <ul>
                <li><a href="#">Contact Us</a></li>
                <li><a href="#">Blog</a></li>
              </ul>
            </div>
            <div class="col-md-3">
              <ul class="social-icon">
                <li>
                  <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-brands fa-twitter"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <!-- /. footer End -->
    </div>
    <script src="js/custom.js"></script>
  </body>

