<header>
    <img class="logo" [src]="'assets/Images/download.jpeg'" alt="Logo">
    <h1>Appy Pie Plugin Marketplace</h1>

    <nav>
      <ul>
        <li><a href="/plugin">Home</a></li>
        <li><a href="/pluginlist">Submitted Plugins</a></li>
        <li><a href="https://www.appypie.com/about-us">About Us</a></li>
        <li><a href="https://www.appypie.com/contact-us">Contact Us</a></li>
        <li><a href="/admin">Admin</a></li>
      </ul>
    </nav>
  </header>
  <div class="plugin">
    <div class="plugin-icon">
        <img [src]="'assets/Images/chess.jpeg'" alt="Plugin Icon">
    </div>
    <div class="plugin-details" *ngIf="plugin">
        <h2 class="plugin-name">{{ plugin.pluginName }}</h2>
        <p class="plugin-description">{{ plugin.pluginDescription }}</p>
        <a href="https://72snappy.pbodev.info/user/app/6d7ace08e55a" class="btn-buy" target="_blank">Buy Now</a>
    </div>
    
    
</div>


<div class="video-container">
       <h3>Please see the below video to understand the plugin feature</h3>
    <iframe
        width="100%"
        height="auto"
        src="https://www.youtube.com/embed/VRzl-nCTsaU"
        frameborder="0"
        allowfullscreen
    ></iframe>
    <div class="video-controls">
        <button>&#9664;</button> 
        <button>▌▌</button> 
        <button>&#9654;</button> 
        <button>&#9658;</button> 
        <div>
            <span>00:00</span> / <span>00:00</span>
        </div>
        <button>🔊</button> 
        <input type="range" min="0" max="1" step="0.1" value="1">
    </div>
</div>

<div class="section-container">
    <div class="section-box">
        <h3 class="toggle-button" (click)="toggleDescription()">Detailed Description</h3>
    <ul *ngIf="showDescription">
            <li>The plugin fetches real-time weather information from weather APIs.</li>
            <li>Users input their location, like a city name or coordinates.</li>
            <li>The plugin sends a request to the weather provider with the specified location.</li>
            <li>It presents the weather data to users in a user-friendly interface, including text descriptions and graphics.</li>
            <li>Many plugins also offer weather forecasts for upcoming days.</li>
            <li>Users can interact with the plugin, adjusting units, viewing maps, and setting notifications.</li>
        </ul>
    </div>

    <div class="section-box">
        <h3 class="toggle-button" (click)="toggleInstallation()">How to Install</h3>
    <ol *ngIf="showInstallation">
        
            <li>First, you need to select a weather plugin that suits your needs. There are various weather plugins available for different platforms, including websites, content management systems (CMS), and mobile apps.</li>
            <li>Go to the source where you can find the weather plugin you want to install. This could be a website, an app store, or a plugin repository, depending on the platform you are using.</li>
            <li>Depending on the platform, you may need to download or install the weather plugin. Here are the specific steps for different platforms:</li>
            <li>If you're installing a weather plugin on your website, you may need to download the plugin files and upload them to your web server. The installation instructions should be provided by the plugin's developer.</li>
            <li>After installing the weather plugin, you'll often need to configure it. This may involve providing location information, API keys (if required), and customizing the appearance or behavior of the weather widget or feature.</li>
        </ol>
    </div>
</div>










