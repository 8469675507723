

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="" content="">
    <title>Market Place</title>

    <link rel="preload" as="style" onload="this.onload=null;this.rel='stylesheet'" href="https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,500&display=swap" rel="stylesheet">
    <!-- js files -->
    <script src="src/js/jquery.min.3.2.js"></script>
    <script src="src/js/bootstrap.bundle.min.js"></script>

    <!-- css files -->
    <link rel="stylesheet" type="text/css" href="/Users/ramjilal/Desktop/Weather-Plugin/Plugin Market/src/css/style.css">
    <link rel="stylesheet" type="text/css" as="style" href="/Users/ramjilal/Desktop/Weather-Plugin/Plugin Market/src/css/style.css" all="media">
</head>
<body>
    <div class="wrapper">
        <!-- Header Start -->
        <header class="site-header fixed-top">
            <nav class="navbar navbar-expand-xl bg-white py-3 py-lg-0 shadow-sm">
                <div class="container">
                    <a class="navbar-brand" href="#" aria-label="Market Place" title="Market Place">
                        <img [src]="'assets/Images/logo.svg'" alt="Market Place" width="160" height="40" />
                    </a>
                    
                    <div class="collapse navbar-collapse" id="navbarNavDropdown">
                        <div class="d-flex align-items-center justify-content-between g-3 mb-3">
                            <a class="navbar-brand d-lg-none" href="#" aria-label="Market Place" title="Market Place">
                                <img [src]="'assets/Imagess/logo.svg'" alt="Market Place" width="160" height="40" />
                            </a>
                            <button class="btn btn-light text-light closebtn-navbar d-block d-xl-none" type="button"><i class="fa-close fa-lg fa-regular fa-fw"></i></button>
                        </div>
                        <ul class="navbar-nav mx-auto gap-lg-4">
                            <li class="nav-item">
                                <a href="#" title="Browse" class="nav-link active">Browse</a>
                            </li>
                            <li class="nav-item">
                                <a href="#" title="Manage" class="nav-link">Manage</a>
                            </li>
                            <li class="nav-item">
                                <a href="#" title="Search" class="nav-link text-black"><i class="fa-regular fa-magnifying-glass"></i> Search</a>
                            </li>
                        </ul>
                    </div>
                    <div class="header-search-wrap d-none d-md-block">
                        <ul class="d-flex gap-3">
                            <li class="nav-item">
                                <a href="#" class="nav-link text-black" title="Notification"><i class="fa-solid fa-bell"></i> <span class="live"></span></a>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle text-black" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  My Profile
                                </a>
                                <ul class="dropdown-menu">
                                  <li><a class="dropdown-item" href="https://commonlogin.pbodev.info/home">Profile</a></li>
                                  <li><a class="dropdown-item" href="#">Log Out <i class="fa-solid fa-right-from-bracket"></i></a></li>
                                  
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </div>
            </nav>
            <!-- /. nav end -->
        </header>
        <!-- /. Header End -->

        <!-- Main Start -->
        <main class="main-body">
            <section class="collections-sec py-5 bg-white">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <a href="index.html" class="fw-medium mb-4 d-inline-block"> <i class="fa-regular fa-arrow-left"></i> Back to App Store</a>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="collections-list mb-5">
                                <h2>Collections</h2>
                                <ul>
                                    <li><a href="#">Featured</a></li>
                                    <li><a href="#">Most Popular</a></li>
                                    <li><a href="#">Essential Apps</a></li>
                                    <li><a href="#">My Apps</a></li>
                                </ul>
                            </div>
                            <div class="collections-list mb-5">
                                <h2>Works with</h2>
                                <ul>
                                    <li><a href="#">Web Products</a></li>
                                    <li><a href="#">On Premise</a></li>
                                    <li><a href="#">Virtual Environments</a></li>
                                    <li><a href="#">Infrastructure</a></li>
                                </ul>
                            </div>
                            <div class="collections-list mb-5">
                                <h2>Categories</h2>
                                <ul>
                                    <li><a href="#">All Apps</a></li>
                                    <li><a href="#">Analytics</a></li>
                                    <li><a href="#">Communication</a></li>
                                    <li><a href="#">Customer Support</a></li>
                                    <li><a href="#">File Management</a></li>
                                    <li><a href="#">Productivity</a></li>
                                    <li><a href="#">Developer Tools</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-8 col-lg-9">
                            <form class="form-search mb-4 border-bottom pb-4">
                                <div class="input-group">
                                    <input class="form-control border" type="search" placeholder="Plugin" id="search">
                                    <span class="input-group-append">
                                        <button class="btn bg-white border-0 h-100 ms-n5" type="button">
                                            <i class="fa-regular fa-magnifying-glass fa-fw fa-lg"></i>
                                        </button>
                                    </span>
                                </div>
                            </form>
                            <div class="d-flex align-items-center flex-wrap justify-content-between gap-3 mb-4">
                                <h2 class="mb-0">Results for “Plugins”</h2>
                                <a href="#">Sort by name <i class="fa-regular fa-arrow-down"></i></a>
                            </div>
                            <div class="plugins-wrap d-flex gap-3 align-items-center flex-column border rounded flex-lg-row mb-4">
                                <div class="recommended-icon bg-purplelight rounded">
                                    <span class="svgicon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="48" viewBox="0 0 44 48">
                                            <path id="Icon" d="M0,10.909,22,0,44,10.909V37.091L22,48,0,37.091Z" fill="#fedfdf"/>
                                        </svg>                                                                                       
                                    </span>
                                </div>
                                <div class="recommended-inner">
                                    <h3 class="fs-6 fw-bold">Plugin</h3>
                                    <p><small>Analytics</small> <small class="price">$89.99/mo</small></p>
                                    <p class="mb-0">With this Application you can collaborate with teammates at any time, from any where in the world. Send an email, text message, start a video call or create a new project thread all in…</p>
                                </div>
                                <div class="plugins-more">
                                    <a href="#" class="moreview"><i class="fa-regular fa-arrow-right fa-fw fa-lg"></i></a>
                                </div>
                            </div>
                            <div class="plugins-wrap d-flex gap-3 align-items-center flex-column border rounded flex-lg-row mb-4">
                                <div class="recommended-icon bg-redlight rounded">
                                    <span class="svgicon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                            <path id="Icon" d="M0,0H48V37.091H19.79L0,48Z" fill="#ebf9ea"/>
                                        </svg>                                                                                        
                                    </span>
                                </div>
                                <div class="recommended-inner">
                                    <h3 class="fs-6 fw-bold">Extension Video</h3>
                                    <p><small>Productivity</small> <small class="price">Free</small></p>
                                    <p class="mb-0">With this Application you can collaborate with teammates at any time, from any where in the world. Send an email, text message, start a video call or create a new project thread all in…</p>
                                </div>
                                <div class="plugins-more">
                                    <a href="#" class="moreview"><i class="fa-regular fa-arrow-right fa-fw fa-lg"></i></a>
                                </div>
                            </div>
                            <div class="plugins-wrap d-flex gap-3 align-items-center flex-column border rounded flex-lg-row mb-4">
                                <div class="recommended-icon bg-greenlight rounded">
                                    <span class="svgicon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                            <path id="Icon" d="M17.172,46.829l-16-16a4,4,0,0,1,0-5.656l24-24A3.978,3.978,0,0,1,28,0H40a8,8,0,0,1,8,8V20a3.982,3.982,0,0,1-1.172,2.829l-24,24a4,4,0,0,1-5.656,0Z" fill="#ebf9ea"/>
                                        </svg>
                                                                                                                             
                                    </span>
                                </div>
                                <div class="recommended-inner">
                                    <h3 class="fs-6 fw-bold">Video Plugin</h3>
                                    <p><small>Productivity</small> <small class="price">Free</small></p>
                                    <p class="mb-0">With this Application you can collaborate with teammates at any time, from any where in the world. Send an email, text message, start a video call or create a new project thread all in…</p>
                                </div>
                                <div class="plugins-more">
                                    <a href="#" class="moreview"><i class="fa-regular fa-arrow-right fa-fw fa-lg"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="app-store-sec py-5 bg-light-color">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="app-store text-center py-5">
                                <h2 class="fs-1">List Your App in our App Store</h2>
                                <p>Register as an app developer and submit your app easily with our App Store Developer Portal</p>
                                <a href="https://pluginmarket.pbodev.info/home" class="btn btn-primary mt-4 btn-md fw-semibold py-2 px-3">Get Started as an App Developer <i class="fa-regular fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <!-- /. Main End -->
        <!-- footer start -->
        <footer class="site-footer bg-white py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <a href="#" class="mb-4 d-inline-block">
                            <img [src]="'assets/Images/logo.svg'" alt="Market Place" width="160" height="40" />
                        </a>
                    </div>
                    <div class="col-md-2">
                        <h3>Browse</h3>
                        <ul>
                            <li><a href="#">Most Popular</a></li>
                            <li><a href="#">Essential Apps</a></li>
                        </ul>
                    </div>
                    <div class="col-md-2">
                        <h3>Developers</h3>
                        <ul>
                            <li><a href="#">Documentation</a></li>
                            <li><a href="#">Getting Started </a></li>
                            <li><a href="#">API Reference
                            </a></li>
                            <li><a href="#">Sample Code</a></li>
                        </ul>
                    </div>
                    <div class="col-md-2">
                        <h3>Company</h3>
                        <ul>
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">Blog</a></li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <ul class="social-icon">
                            <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <!-- /. footer End -->
    </div>
    <script src="js/custom.js"></script>
</body>
