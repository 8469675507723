
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Plugin Submission Guidelines</title>
    
</head>
<body>
    <header>
        <img class="logo" src="assets/Images/download.jpeg" alt="Logo">
        <h1>Appy Pie Plugin Marketplace</h1>
        <nav>
            <ul>
                <li><a href="/plugin">Home</a></li>
                <li><a href="/pluginlist">Submitted Plugins</a></li>
                <li><a href="https://www.appypie.com/about-us">About Us</a></li>
                <li><a href="https://www.appypie.com/contact-us">Contact Us</a></li>
                <li><a href="/admin">Admin</a></li>
            </ul>
        </nav>
    </header>

    <main>
        <section class="guidelines">
            <h2>Plugin Submission Guidelines</h2>
            <ol>
                <li>The plugin name should be related to the concept of the plugin feature.</li>
                <li>Choose a plugin category that is relevant to your plugin.</li>
                <li>The icon image should be in a standard image format, and it should not have a double extension or contain multiple file formats with image extensions.</li>
                <li>The icon size should be 512x512 pixels, and its file size should be less than 5 MB.</li>
                <li>Provide a description of the plugin's features in the description column.</li>
                <li>The video URL should be accessible and in either .mov or .mp4 format.</li>
                <li>The manifest file and YAML file should adhere to the following format.</li>
                <li>A manifest file is a configuration file that provides metadata and instructions about a software package or component. It typically includes information such as the package's name, version, dependencies, author, and other relevant details.</li> 
                <li>YAML is a human-readable data serialization format. In software development, YAML files are often used for configuration, settings, or data representation. They are commonly used for defining configuration settings for applications and services. </li>
                <li>Refernces for the Manifest and Yaml Files</li>
                <a href="https://www.appypie.com/.well-known/ai-plugin.json"> Manifest file</a>
                <a href="https://www.appypie.com/.well-known/openapi.yaml"> Yaml File</a>
            </ol>
        </section>
    </main>
</body>

