import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '../cookie.service';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../session.service';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'app-plugin-draft',
  templateUrl: './plugin-draft.component.html',
  styleUrls: ['./plugin-draft.component.css']
})
export class PluginDraftComponent {

  plugin: any;
  showDescription: boolean = false;
  showInstallation: boolean = false;
  selectedPlugin: any | null = null; 

  constructor(private http: HttpClient, private cookies:CookieService, private route: ActivatedRoute, private session: SessionService,private api:ApiService) { }

  ngOnInit(): void {
    // this.cookies.authservice();
    this.route.params.subscribe(params => {
      console.log("I am starting");
      const pluginid = params['pluginid'];
      this.api.get(`form-submissions/${pluginid}`).subscribe(
        (data: any) => {
          this.plugin = data;
        },
        (error) => {
          console.error("Error in HTTP request:", error);
        }
      );
    });
  }



  buyNow(pluginid: string): void {
    
    const externalURL = `https://72snappy.pbodev.info/user/app/${pluginid}`;
    window.open(externalURL);
  }

  logOut() {
    console.log("I am draft component");
    this.session.logOut();
    this.cookies.deleteAuthCookies();
    window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}/plugin`;
  }
  
  setSelectedPlugin(plugin: any) {
    console.log("I am plugin details", plugin);
    this.selectedPlugin = plugin;
  }
  

}
