import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';
import AES from 'crypto-js/aes';
import ENC from 'crypto-js/enc-utf8';



@Injectable({ providedIn: 'root' })

export class cryptography {
    private  encryptionKey : string= 'appypie';
    private iv : any ="appypie"; 

    decrypt(encryptedData: string): string {
      
        const {iv,ct} = JSON.parse(encryptedData);
        const bytes = CryptoJS.AES.decrypt(ct, this.encryptionKey, { iv });
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        console.log(decryptedData,'de');
        
        return JSON.parse(decryptedData);
      }

      encrypt(data:string):string {

        const ivValue = this.iv;
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptionKey, { ivValue }).toString();
        const encryptData = {
            iv: ivValue.toString(CryptoJS.enc.Hex),
          ct:encrypted
        };

        return JSON.parse(JSON.stringify(encryptData));
      }
}