import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { cryptography } from './cryptography';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiUrl = environment.apiUrl;
  public commanUrl = environment.url;
  private status: number = 0;
  constructor(private http: HttpClient, private change: cryptography) {
    const statusString = localStorage.getItem('cryptoStatus');
    this.status = statusString ? Number(statusString) : 0;
  }

  get(endpoint: string): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;
    const token = this.getTokenCookie('authToken');
    const headers = new HttpHeaders({
      'Authorization': token ? `Bearer ${token}` : 404
    });
    return this.http.get(url, { headers }).pipe(
      map(response => {
        if (this.status !== 0) {
          // Decrypt the response if status is not 0
          return this.change.decrypt(JSON.stringify(response));
        }
        return response;
      })
    );
  }


  post(endpoint: string, data: any = {}, headers?: HttpHeaders): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;
    let options = {};
    const token = this.getTokenCookie('authToken');
    headers = headers ?
      headers.set('Authorization', `Bearer ${token}`) :
      new HttpHeaders({ 'Authorization': `Bearer ${token}` });
    if (headers) {
      options = { headers };
    }

    if (this.status === 0) {
      return this.http.post<any>(url, data, options);
    } else {
      return this.http.post<any>(url, this.change.encrypt(data), options);
    }
  }

  getTokenCookie(cname: string): string {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    console.log(decodedCookie, 'decode');

    const cookies = decodedCookie.split(';');
    let authCookiesData = '';
    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[i];
      console.log(c, 'c');

      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        authCookiesData = c.substring(name.length, c.length);
      }
    }
    return authCookiesData;
  }
}
