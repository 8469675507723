import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '../session.service';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'app-plugins',
  templateUrl: './plugins.component.html',
  styleUrls: ['./plugins.component.css']
})
export class PluginsComponent implements OnInit {
  approvedPlugins: any[] = [];
  searchQuery: string = ''; 
  filteredPlugins: any[] = []; 

  constructor(private httpClient: HttpClient, private session: SessionService,private api:ApiService) { }

  ngOnInit(): void {
    //this.session.authservice();
    if (localStorage.getItem('isFirstTime') === 'true') {
      // Create the session for first-time users
      this.session.createSession();
      localStorage.setItem('isFirstTime', 'false');
    }

    this.api.get('form-submissions').subscribe((data:any[]) => {
      this.approvedPlugins = data.filter(plugin => plugin.status === 'Approved');
      this.filteredPlugins = [...this.approvedPlugins]; 
    });
  }

  filterPlugins() {
    this.filteredPlugins = this.approvedPlugins.filter((plugin) => {
      return plugin.pluginName.toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  }
}
