import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '../cookie.service';
import { Router } from '@angular/router';
import { SessionService } from '../session.service';
import { ApiService } from 'src/api.service'
import { timeout, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  approvedPlugins: any[] = [];
  searchQuery: string = '';
  filteredPlugins: any[] = [];
  selectedCategory: string | null = null;

  constructor(private httpClient: HttpClient, private cookies: CookieService, private router: Router, private session: SessionService, private api: ApiService) { }


  ngOnInit(): void {
    console.log('First NgOnIt');
    this.fetchDataOfPlugin();

  }
  async fetchDataOfPlugin(): Promise<void> {
    try {
      if (localStorage.getItem('isFirstTime') === 'true') {
        this.session.createSession();
        localStorage.setItem('isFirstTime', 'false');
      }
      this.api.get('form-submissions').subscribe((data: any[]) => {
        console.log('Third Get Data');

        this.approvedPlugins = data.filter(plugin => plugin.pluginStatus === 'Approved');
        this.approvedPlugins.forEach(plugin => {
          console.log("Selected file:", plugin.selectedFile);
        });
        this.filteredPlugins = [...this.approvedPlugins];
      });
    } catch (error) {
      console.error('Error during operations:', error);
    }
  }
  filterByCategory(category: string) {
    this.selectedCategory = category;
    if (category === 'All') {
      this.filteredPlugins = this.approvedPlugins;
    } else {
      this.filteredPlugins = this.approvedPlugins.filter(plugin => plugin.pluginCategory === category);
    }
  }

  logOut() {
    this.session.logOut();
    this.cookies.deleteAuthCookies();
    window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}`;
  }

}
