import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { UserService } from './user-service.service';
import axios from 'axios';
import { ApiService } from 'src/api.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private userData: any; 

  constructor(private router: Router, private http: HttpClient, private cookie:CookieService, private user: UserService,private api:ApiService) {
    this.userData = {}; 
  }


  async checkSession(){
    const activeStatus = this.cookie.getAuthCookies('79fefaf2564aabb19d44fe324844c86b');
    if(activeStatus === '1'){
      return true;
    }
    else {
      window.location.href = 'https://commonlogin.pbodev.info/login';
      return true;
    }
  }


  async createSession() {
    const keydata = this.cookie.getAuthCookies('keydata');
    const APPYID  = this.cookie.getAuthCookies('APPYID');

    try {
      const response = await axios.post(`${this.api.commanUrl}/api/createSession`, {
        APPYID,
        keydata,
      });

      if (response.data.message) {
        console.log(response.data.message);
      
      } else {
        console.error('Failed to create session');
        
      }
    } catch (error) {
      console.error('Error creating session:', error);
      
    }
  }


 async logOut() {
  console.log("I am deleting the session");
  try {
    const keydata = this.cookie.getAuthCookies('keydata');
    const userData = await this.user.fetchUserDetails(keydata);
    if (userData) {
      const email = userData.email;
      console.log("Before axios.post");
      this.cookie.deleteAuthCookies();
      await axios.post(`${this.api.commanUrl}/api/logout`, { email });
      console.log("After axios.post");
    }
    window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}`;
  } catch (error) {
    console.error('Error:', error);
    window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}`;
  }
}

  
  

}
