
<header>
  <img class="logo" [src]="'assets/Images/download.jpeg'" alt="Logo">
  <h1>Appy Pie Plugin Marketplace</h1>

  <nav>
    <ul>
      <li><a href="/plugin">Home</a></li>
      <li><a href="/pluginlist">Submitted Plugins</a></li>
      <li><a href="https://www.appypie.com/about-us">About Us</a></li>
      <li><a href="https://www.appypie.com/contact-us">Contact Us</a></li>
      <li><a href="/admin">Admin</a></li>
    </ul>
  </nav>
</header>

<h2 style="text-align: center;">Admin Page</h2>
<table>
  <tr>
    <th>Plugin Name</th>
    <th>Plugin Category</th>
    <th>Plugin Description</th>
    <th>Website</th>
    <th>Yaml File</th>
    <th>Manifest File</th>
    <th>Demo Video</th>
    <th>Summary</th>
    <th>Status</th>
    <th>Actions</th>
  </tr>
  <tr *ngFor="let data of formData">
    <td>{{ data.pluginName }}</td>
    <td>{{ data.pluginCategory }}</td>
    <td>{{ data.pluginDescription }}</td>
    <td>{{ data.websiteUrl }}</td>
    <td>{{ data.yamlfile }}</td>
    <td>{{ data.manifestfile }}</td>
    <td>{{ data.videoUrl }}</td>
    <td>{{ data.summary }}</td>
    <td>{{ data.status }}</td>
    <td>
      <button class="approve" (click)="approve(data)">Approve</button>
      <button class="reject" (click)="reject(data)">Reject</button>
    </td> 
  </tr>
</table>
