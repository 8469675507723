<div>
  <header>
    <img class="logo" [src]="'assets/Images/download.jpeg'" alt="Logo">
    <h1>Appy Pie Plugin Marketplace</h1>

    <nav>
      <ul>
        <li><a href="/plugin">Home</a></li>
        <li><a href="/pluginlist">Submitted Plugins</a></li>
        <li><a href="https://www.appypie.com/about-us">About Us</a></li>
        <li><a href="https://www.appypie.com/contact-us">Contact Us</a></li>
        <li><a href="/admin">Admin</a></li>
      </ul>
    </nav>
  </header>
    <h2>Submitted Plugins</h2>
    <table>
      <tr>
        <th>Plugin Name</th>
        <th>Plugin Category</th>
        <th>Plugin Description</th>
        <th>Website</th>
        <th>Yaml File</th>
        <th>Manifest File</th>
        <th>Demo Video</th>
        <th>Summary</th>
        <th>Status</th>
      </tr>
      <tr *ngFor="let plugin of submittedPlugins">
        <td>{{ plugin.pluginName }}</td>
        <td>{{ plugin.pluginCategory }}</td>
        <td>{{ plugin.pluginDescription }}</td>
        <td>{{ plugin.websiteUrl }}</td>
        <td>{{ plugin.yamlfile }}</td>
        <td>{{ plugin.manifestfile }}</td>
        <td>{{ plugin.videoUrl }}</td>
        <td>{{ plugin.summary }}</td>
        <td>{{ plugin.status }}</td>
      </tr>
    </table>
  </div>
  