import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './auth.guard';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { LoginComponent } from './login/login.component';
import { PluginFormComponent } from './plugin-form/plugin-form.component';
import { PluginListComponent } from './plugin-list/plugin-list.component';
import { PluginsComponent } from './plugins/plugins.component';
import { PlugindetailsComponent } from './plugindetails/plugindetails.component';
import { RegisterComponent } from './registration/registration.component';
import { HomeComponent } from './home/home.component';
import { PluginDetailsComponent } from './plugin-details/plugin-details.component';
import { PluginDisplayComponent } from './plugin-display/plugin-display.component';
import { PluginManageComponent } from './plugin-manage/plugin-manage.component';
import { PluginDraftComponent } from './plugin-draft/plugin-draft.component';
import { PluginStatusComponent } from './plugin-status/plugin-status.component';
import { PluginSubmissionComponent } from './plugin-submission/plugin-submission.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'protected',
    pathMatch: 'full',
  },
  { path: 'admin', component: AdminComponent },
  { path: 'pluginlist', component: PluginListComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'home', component: PluginFormComponent },
  { path: 'plugin', component: PluginsComponent },
  { path: 'guide', component: GuidelinesComponent },
  { path: 'start', component: HomeComponent },
  { path: 'details/:pluginid', component: PluginDetailsComponent },
  { path: 'display', component: PluginDisplayComponent },
  { path: 'manage', component: PluginManageComponent },
  { path: 'draft/:pluginid', component: PluginDraftComponent },
  { path: 'status', component: PluginStatusComponent },
  { path: 'plugindetails/:pluginid', component: PlugindetailsComponent },
  { path: 'submit', component: PluginSubmissionComponent },
  { path: 'profile', component: UserProfileComponent },

  {
    path: 'protected',
    canActivate: [AuthGuard],
    children: [
      { path: 'plugin', component: PluginsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
