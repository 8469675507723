import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  username: string = '';
  password: string = '';
  successMessage: string = ''; 

  constructor( private router: Router,private api: ApiService) {}

  onSubmit() {
    const user = { username: this.username, password: this.password };

    this.api.post('login', user).subscribe(
      (response: any) => {
        console.log("I am login ", response);
        this.successMessage = 'Login successful'; 
        setTimeout(() => {
          this.router.navigate(['/plugin']);
        }, 1000);
      },
      (error: any) => {
        console.error(error);
        this.successMessage = 'Login Failed'; 
      }
    );
  }
}
