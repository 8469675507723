import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from 'src/api.service';
import { CookieService } from './cookie.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router,private api:ApiService, private cookie: CookieService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) { // Or any other status indicating session timeout
          // Redirect to the login page
          // this.cookie.deleteCookie('APPYID');
          // this.cookie.deleteCookie('keydata');
          // this.cookie.deleteCookie('79fefaf2564aabb19d44fe324844c86b');
          // window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}`;
        }
        return throwError(error);
      })
    );
  }
}
