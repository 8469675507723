import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '../cookie.service';
import { Router } from '@angular/router';
import { SessionService } from '../session.service';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {

  constructor(private httpClient: HttpClient, private cookies:CookieService, private router: Router, private session: SessionService,private api:ApiService) { }

 url = 'https://commonlogin.pbodev.info/home';
  

  redirectToExternalLink(url: string): void {
    window.open(url, '_blank'); 
  }
  
 logOut() {
    this.session.logOut();
    this.cookies.deleteAuthCookies();
    window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}`;
  }
}
