

    <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="" content="">
    <title>Market Place</title>

    <link rel="stylesheet" as="style" onload="this.onload=null;this.rel='stylesheet'" href="https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800;900&amp;family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,500&amp;display=swap" rel="stylesheet">
    <!-- js files -->
    <script src="src/js/jquery.min.3.2.js"></script>
    <script src="src/js/bootstrap.bundle.min.js"></script>

    <!-- css files -->
    <link rel="stylesheet" type="text/css" href="/Users/ramjilal/Desktop/Weather-Plugin/Plugin Market/src/css/style.css">
    <link rel="stylesheet" type="text/css" as="style" href="/Users/ramjilal/Desktop/Weather-Plugin/Plugin Market/src/css/style.css" all="media">
</head>
<body class="bg-light-color">
    <div class="wrapper">
        <!-- Header Start -->
        <header class="site-header fixed-top">
            <nav class="navbar navbar-expand-xl bg-white py-3 py-lg-0 shadow-sm">
                <div class="container">
                    <a class="navbar-brand" href="#" aria-label="Market Place" title="Market Place">
                        <img [src]="'assets/Images/appylogo.png'" alt="Market Place" width="160" height="40" />
                    </a>
                    
                    <div class="collapse navbar-collapse" id="navbarNavDropdown">
                        <div class="d-flex align-items-center justify-content-between g-3 mb-3">
                            <a class="navbar-brand d-lg-none" href="#" aria-label="Market Place" title="Market Place">
                                <img src="images/logo.svg" alt="Market Place" width="160" height="40">
                            </a>
                            <button class="btn btn-light text-light closebtn-navbar d-block d-xl-none" type="button"><i class="fa-close fa-lg fa-regular fa-fw"></i></button>
                        </div>
                        <ul class="navbar-nav mx-auto gap-lg-4">
                            <li class="nav-item">
                                <a routerLink="/start" title="Browse" class="nav-link active">Home</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/manage" title="Manage" class="nav-link">Plugins</a>
                            </li>
                            <li class="nav-item d-xl-none">
                                <a href="#" title="Manage" class="nav-link">notifications</a>
                            </li>
                            <li class="nav-item dropdown d-xl-none">
                                    
                                <a class="nav-link dropdown-toggle fw-medium text-black" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                                    <i class="fa fa-user me-2" aria-hidden="true"></i>  Profile
                                </a>
                                <ul class="dropdown-menu dropdown-menu-lg-end " aria-labelledby="navbarDropdown" data-bs-popper="none">
                                  <li><a class="dropdown-item" routerLink="/profile" >Profile</a></li>
                                  <li><a class="dropdown-item" >Logout</a></li>
                                </ul>
                              </li>
                            
                            
                        </ul>
                    </div>
                    <div class="header-search-wrap d-none  d-xl-block">
                        <ul class="d-flex gap-4">
                            <li class="nav-item">
                                <a href="#" title="Search" class="nav-link fw-medium text-black" data-bs-toggle="collapse" data-bs-target="#searchBar" aria-expanded="false" aria-controls="searchBar"><i class="fa-regular fa-magnifying-glass"></i> Search</a>
                            </li>
                            <li class="nav-item bell-icon mx-3 d-none  d-xl-block">
                                <a class="nav-link  fw-medium text-black" href="#"><i class="fa fa-bell" aria-hidden="true"></i></a>
                            </li>
                                <li class="nav-item dropdown d-none d-xl-block">
                                    
                                <a class="nav-link dropdown-toggle fw-medium text-black" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                                    <i class="fa fa-user me-2" aria-hidden="true"></i>  Profile
                                </a>
                                <ul class="dropdown-menu dropdown-menu-lg-end " aria-labelledby="navbarDropdown" data-bs-popper="none">
                                  <li><a class="dropdown-item" routerLink="/profile">Profile</a></li>
                                  <li><a class="dropdown-item" (click)="logOut()">Logout</a></li>
                                </ul>
                              </li>

                        </ul>
                    </div>
                    <div class="d-flex d-flex d-xl-none">
                        <a href="#" title="Search" class="mob-search nav-link fw-medium text-black " data-bs-toggle="collapse" data-bs-target="#searchBar" aria-expanded="false" aria-controls="searchBar"><i class="fa-regular fa-magnifying-glass"></i></a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </div>
                   
                </div>
            </nav>
            <section class="search-bar collapse" id="searchBar">
               <div class="container">
                    <div class="form-group with-icon">
                    <input type="text" class="form-control" placeholder="Search here">
                    <i class="fa-regular fa-magnifying-glass" aria-hidden="true"></i>
                  </div>
               </div>
            </section>
            <!-- /. nav end -->
        </header>
        <!-- /. Header End -->

        <!-- Main Start -->
        <main class="main-body bg-img top-left">
            
            <div class="container">

               

                <div class="row py-5">
                    <div class="col-sm-6">
                        <h2 class="large-headeing">My Apps</h2>
                        
                    </div>
                    <div class="col-sm-6 text-md-end mt-4 mt-md-0">
                        <a routerLink="/submit" class="btn btn-primary mx-auto fw-bold buynow-btn py-2 px-5">Submit New App</a>
                    </div>
                </div>
                <section class="bg-white p-5 mb-5">
                    <div class="row">
                        <div class="col-md-3">
                          <button
                            type="button"
                            class="btn btn-primary dropdown-toggle dropdown-toggle-split px-4 w-100"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Downloads
                          </button>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">Action 1</a></li>
                            <li><a class="dropdown-item" href="#">Action 2</a></li>
                            <li><a class="dropdown-item" href="#">Action 3</a></li>
                            <li><hr class="dropdown-divider" /></li>
                            <li><a class="dropdown-item" href="#">Separated link</a></li>
                          </ul>
                        </div>
                        <div class="col-md-9 text-end">
                          <div
                            class="radio-wrap d-flex justify-content-md-end justify-content-center flex-column flex-md-row mt-2 mt-md-0"
                          >
                            <div class="mb-3">
                              <label class="form-check-label btn">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                                Option 1
                              </label>
                            </div>
                            <div class="mb-3">
                              <label class="form-check-label btn">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                                Option 2
                              </label>
                            </div>
                            <div class="mb-3">
                              <label class="form-check-label btn">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                                Option 3
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                    <div class="row mt-5">
                        <div class="col-md-9">
                            <div class="pic-wrap">
                                <img [src]="'assets/Images/graph-img.png'" class="w-100" alt="">
                            </div>
                        </div>
                        <div class="col-md-3 d-flex align-items-center justify-content-center mt-3 mt-md-0">
                            <div class="g-icon-wrap text-center">
                                <i class="fa fa-cloud-download text-primary" aria-hidden="true"></i>
                                <p class="mt-2"><strong>10 099</strong></p>
                                <div class="divider my-3"></div>
                                <p>Total Downloads</p>
                            </div>
                        </div>
                    </div>
                    <div class="divider my-3 mt-5"></div>
                    <div class="table-responsive ">
                    <table class="table data-table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <button class="btn p-0">App Name <i class="fa fa-angle-down" aria-hidden="true"></i></button>
                            </th>
                            <th scope="col"> <button class="btn p-0">Description <i class="fa fa-angle-down" aria-hidden="true"></i></button></th>
                            <th scope="col"> <button class="btn p-0">Data Added <i class="fa fa-angle-down" aria-hidden="true"></i></button></th>
                            <th scope="col"> <button class="btn p-0">Status <i class="fa fa-angle-down" aria-hidden="true"></i></button></th>
                            <th scope="col"> </th>
                          </tr>
                        </thead>
                        <tbody *ngFor="let plugin of submittedPlugins" >
                          <!-- <tr >
                            <td>
                                <h5 class="d-block d-md-none">App Name</h5>
                               <div class="item-wrap">
                                <div class="pic-wrap">
                                    <img [src]="'assets/Images/2.png'" alt="">
                                </div>
                                <div class="content-wrap">
                                    <h5><strong>Integration</strong></h5>
                                    <p>Productivity</p>
                                </div>
                               </div>
                            </td>
                            <td><p>With this Application you can collaborate with teammates at any time</p></td>
                            <td><h5 class="d-block d-md-none">Date Added</h5><p>2/22/2019</p></td>
                            <td> <h5 class="d-block d-md-none">Status</h5><p class="text-primary">Draft</p></td>
                            <td>
                                <div class="btn-row">
                                    <button type="button" class="btn btn-primary" (click)="goToDraft()">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                      </button>
                                      
                                    
                                    <button type="button" class="btn btn-primary " data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                    <li><a class="dropdown-item" href="#"><i class="fa fa-external-link" aria-hidden="true"></i> Publish</a></li>
                                    <li><a class="dropdown-item" href="#"><i class="fa fa-trash" aria-hidden="true"></i> Delete</a></li> 
                                    </ul>
                                  </div>
                            </td>
                          </tr> -->
                          <tr>
                            <td>
                                <h5 class="d-block d-md-none">App Name</h5>
                               <div class="item-wrap">
                                <div class="pic-wrap">
                                    <img [src]="'assets/Images/3.jpeg'" alt="">
                                </div>
                                <div class="content-wrap">
                                    <h5>{{ plugin.pluginName }}</h5>
                                    <p>{{ plugin.pluginCategory }}</p>
                                </div>
                               </div>
                            </td>
                            <td><p>{{ plugin.pluginDescription }}</p></td>
                            <td><h5 class="d-block d-md-none">Date Added</h5><p>{{ formatDate(plugin.date) }}</p></td>
                            <td> <h5 class="d-block d-md-none">Status</h5><p class="text-primary">{{ plugin.status }}</p></td>
                            <td>
                                <div class="btn-row">
                                    <button type="button" class="btn btn-primary" (click)="goToDraft(plugin.pluginid)">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                      </button>
                                      
                                      
                                    
                                    <button type="button" class="btn btn-primary " data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                    <li *ngIf="plugin.status === 'Draft'">
                                        <a class="dropdown-item"  (click)="publishPlugin(plugin)">
                                            <i class="fa fa-external-link" aria-hidden="true"></i> Publish
                                          </a>
                                          <li><a class="dropdown-item" (click)="deletePlugin(plugin)">
                                            <i class="fa fa-trash" aria-hidden="true"></i> Delete
                                          </a></li>
                                    </ul>
                                  </div>
                            </td>
                          </tr>
                          <!-- <tr>
                            <td>
                                <h5 class="d-block d-md-none">App Name</h5>
                               <div class="item-wrap">
                                <div class="pic-wrap">
                                    <img [src]="'assets/Images/4.png'" alt="">
                                </div>
                                <div class="content-wrap">
                                    <h5><strong>Integration</strong></h5>
                                    <p>Productivity</p>
                                </div>
                               </div>
                            </td>
                            <td><p>With this Application you can collaborate with teammates at any time</p></td>
                            <td><h5 class="d-block d-md-none">Date Added</h5><p>2/22/2019</p></td>
                            <td> <h5 class="d-block d-md-none">Status</h5><p class="text-primary">Draft</p></td>
                            <td>
                                <div class="btn-row">
                                    <button type="button" class="btn btn-primary"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                    
                                    <button type="button" class="btn btn-primary " data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                    <li><a class="dropdown-item" href="#"><i class="fa fa-external-link" aria-hidden="true"></i> Publish</a></li>
                                    <li><a class="dropdown-item" href="#"><i class="fa fa-trash" aria-hidden="true"></i> Delete</a></li> 
                                    </ul>
                                  </div>
                            </td>
                          </tr> -->
                          <!-- <tr>
                            <td>
                                <h5 class="d-block d-md-none">App Name</h5>
                               <div class="item-wrap">
                                <div class="pic-wrap">
                                    <img [src]="'assets/Images/5.png'" alt="">
                                </div>
                                <div class="content-wrap">
                                    <h5><strong>Integration</strong></h5>
                                    <p>Productivity</p>
                                </div>
                               </div>
                            </td>
                            <td><p>With this Application you can collaborate with teammates at any time</p></td>
                            <td><h5 class="d-block d-md-none">Date Added</h5><p>2/22/2019</p></td>
                            <td> <h5 class="d-block d-md-none">Status</h5><p class="text-primary">Draft</p></td>
                            <td>
                                <div class="btn-row">
                                    <button type="button" class="btn btn-primary"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                    
                                    <button type="button" class="btn btn-primary " data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                    <li><a class="dropdown-item" href="#"><i class="fa fa-external-link" aria-hidden="true"></i> Publish</a></li>
                                    <li><a class="dropdown-item" href="#"><i class="fa fa-trash" aria-hidden="true"></i> Delete</a></li> 
                                    </ul>
                                  </div>
                            </td>
                          </tr> -->
                        
                          <!-- Add more rows as needed -->
                        </tbody>
                      </table>
                    </div>
                </section>
            </div>    
            
            
            

            
            
            <!-- /. Recommended section end -->

        </main>
        <!-- /. Main End -->
        <!-- footer start -->
        <footer class="site-footer bg-white py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <a href="#" class="mb-4 d-inline-block">
                            <img src="images/logo.svg" alt="Market Place" width="160" height="40">
                        </a>
                    </div>
                    <div class="col-md-2">
                        <h3>Browse</h3>
                        <ul>
                            <li><a href="#">Most Popular</a></li>
                            <li><a href="#">Essential Apps</a></li>
                        </ul>
                    </div>
                    <div class="col-md-2">
                        <h3>Developers</h3>
                        <ul>
                            <li><a href="#">Documentation</a></li>
                            <li><a href="#">Getting Started </a></li>
                            <li><a href="#">API Reference
                            </a></li>
                            <li><a href="#">Sample Code</a></li>
                        </ul>
                    </div>
                    <div class="col-md-2">
                        <h3>Company</h3>
                        <ul>
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">Blog</a></li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <ul class="social-icon">
                            <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <!-- /. footer End -->
    </div>
    <script src="js/custom.js"></script>

</body>