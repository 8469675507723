import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'app-register', 
  templateUrl: './registration.component.html', 
  styleUrls: ['./registration.component.css'] 
})

export class RegisterComponent {
  username: string = '';
  password: string = '';
  successMessage: string = ''; 

  constructor(private http: HttpClient, private router: Router,private api:ApiService) {}

  // onRegister() {
  //   const user = { username: this.username, password: this.password };

  //   this.http.post('http://localhost:3000/register', user).subscribe(
  //     (response: any) => {
  //       console.log(response); 
  //       console.log(response.status); 
  //       if(response.status === 200)
  //       this.successMessage = 'You Have Successfully Registered'; 
  //       setTimeout(() => {
  //         this.router.navigate(['/home']);
  //       }, 1000);
  //     },
  //     (error: any) => {
  //       console.error(error); 
  //     }
  //   );
  // }


  onRegister() {
    const user = { username: this.username, password: this.password };
    this.api.post('register', user).subscribe(
      (response: any) => {
        console.log(response);
        console.log("I am status" ,response.status);
        if (response.status === '200') {
          this.successMessage = 'You have successfully registered';
          setTimeout(() => {
            this.router.navigate(['/plugin']);
          }, 1000);
        } else  if (response.status === '400'){
          this.successMessage = 'User already exists. Please choose a different username.';
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

}
