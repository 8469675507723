<header>
	<img class="logo" [src]="'assets/Images/download.jpeg'" alt="Logo">
	<h1>Appy Pie Plugin Marketplace</h1>

	<nav>
	  <ul>
		<li><a href="/plugin">Home</a></li>
		<li><a href="/pluginlist">Submitted Plugins</a></li>
		<li><a href="https://www.appypie.com/about-us">About Us</a></li>
		<li><a href="https://www.appypie.com/contact-us">Contact Us</a></li>
		<li><a href="/admin">Admin</a></li>
	  </ul>
	</nav>
  </header>


<div class="form-wrap-container">
	<div class="form-head">
		<h1>Plugin Submission</h1>
		<p>Please read the guidelines before submission the plugin</p>
		<a href="/guide" class="guidelines-link" >Guidelines</a>
	</div>
	<div class="form-body">
		<div class="form-wrapper">
			

			<div class="form-wrap form-inline">
				<label for="pluginName">Plugin Name</label>
				<div class="input-wrap input-group">
					<input type="text" class="form-control" placeholder="" name="pluginName" [(ngModel)]="pluginName">
				</div>
			</div>

			<div class="form-wrap form-inline">
        <label for="pluginCategory">Plugin Category</label>
        <div class="input-wrap">
          <select id="pluginCategory" class="form-control" name="pluginCategory" [(ngModel)]="pluginCategory">
            <option value="Entertainment">Entertainment</option>
            <option value="Transport">Social Network</option>
            <option value="Food">Location</option>
          </select>
        </div>
      </div>
      

			<div class="form-wrap form-inline">
				<label for="pluginDescription">Plugin Description</label>
				<div class="input-wrap">
					<textarea class="form-control" name="pluginDescription" [(ngModel)]="pluginDescription"></textarea>
				</div>
			</div>

			<div class="form-wrap form-inline">
        <label for="websiteUrl">Website URL</label>
				<div class="input-wrap input-group">
					<input type="text" class="form-control" placeholder="" name="websiteUrl" [(ngModel)]="websiteUrl">
				</div>
			</div>

			<div class="form-wrap form-inline">
				<label for="videoUrl">Video URL</label>
				<div class="input-wrap input-group">
					<input type="text" class="form-control" placeholder="" name="videoUrl" [(ngModel)]="videoUrl">
				</div>
			</div>

            <div class="form-wrap form-inline">
              <label for="yamlfile">Yaml File</label>
				<div class="input-wrap input-group">
					<input type="text" class="form-control" placeholder="Https:abc.com/openai.yaml" name="yamlfile" [(ngModel)]="yamlfile">
				</div>
			</div>

            <div class="form-wrap form-inline">
				<label for="manifestfile">Manifest File</label>
				<div class="input-wrap input-group">
					<input type="text" class="form-control" placeholder="" name="manifestfile" [(ngModel)]="manifestfile">
				</div>
			</div>
			

			<div class="form-wrap form-inline">
				<label for="summary">Summary</label>
				<div class="input-wrap">
					<textarea class="form-control" name="summary" [(ngModel)]="summary"></textarea>
				</div>
			</div>

			<div class="form-wrap form-inline">
        <label for="uploadPluginFile">Icon Image</label>
        <div class="fileUpload">
          <input type="file" name="selectedFile" (change)="onFileSelected($event)" class="upload" id="uploadPluginFile">
          
          <span>Upload Icon Image</span>
        </div>
      </div>
      
			<div class="button-row m-t-40">
        <button class="btn btn-submit" [disabled]="!selectedFile" (click)="onSubmit()">Submit Plugin</button>
        <div *ngIf="successMessage" class="success-message">
          {{ successMessage }}
        </div>
      </div>
    
	
		</div>
	</div>
</div>



