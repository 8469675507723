import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from 'src/api.service';


@Injectable({
  providedIn: 'root',
})
export class CookieService {

  constructor(private http: HttpClient, private route: Router,private api:ApiService) { }

  getAuthCookies(cname: string): string {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');
    let authCookiesData = '';
    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        authCookiesData = c.substring(name.length, c.length);
      }
    }
    return authCookiesData;
  }

 deleteCookie(name: string): void {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.pbodev.info; path=/;`;
}


  // deleteAuthCookies(): void {
  //   const cookieNameToDelete = '79fefaf2564aabb19d44fe324844c86b';

  //   console.log("I am deleting the cookies");

  //   // Log the cookies before deletion
  //   console.log("Before deleting cookies", document.cookie);

  //   // Delete the cookie with the specified path
  //   this.deleteCookie(cookieNameToDelete);

  //   // Log the cookies after a short delay
  //   setTimeout(() => {
  //     console.log("After deleting cookies", document.cookie);
  //   }, 1000);
  // }


deleteAuthCookies(): void {
  const cookiesToDelete = ['79fefaf2564aabb19d44fe324844c86b', 'keydata', 'APPYID'];

  console.log("I am deleting the cookies");

  // Log the cookies before deletion
  console.log("Before deleting cookies", document.cookie);

  // Delete each cookie in the array
  cookiesToDelete.forEach(cookieName => {
    this.deleteCookie(cookieName);
  });

  // Log the cookies after a short delay
  setTimeout(() => {
    console.log("After deleting cookies", document.cookie);
  }, 1000);
}




  sendCookiesToServer() {
    const cookies = document.cookie;
    const headers = new HttpHeaders().set('Cookie', cookies);
    this.api.post('cookies', {},  headers ).subscribe(
      (response) => {
        console.log('Cookies sent to server');
      },
      (error) => {
        console.error('Error sending cookies:', error);
      }
    );
  }



  authservice(){
    const login_status = this.getAuthCookies('79fefaf2564aabb19d44fe324844c86b');
    if(login_status === '1')
    {
      return true;
    }
    else {
      window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}`;
      return false;
    }
  }


  setCookie(name: string, value: string, days: number, path: string = '/'): void {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    const cookieValue = `${name}=${value};expires=${expires.toUTCString()};path=${path};`;
    document.cookie = cookieValue;
  }

  
  

  
}
