<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="" content="">
  <title>Market Place</title>

  <link rel="stylesheet" as="style" onload="this.onload=null;this.rel='stylesheet'"
    href="https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css">
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800;900&amp;family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,500&amp;display=swap"
    rel="stylesheet">
  <!-- js files -->
  <script src="src/js/jquery.min.3.2.js"></script>
  <script src="src/js/bootstrap.bundle.min.js"></script>

  <!-- css files -->
  <link rel="stylesheet" type="text/css" href="/var/www/vhosts/pluginmarket.pbodev.info/src/css/style.css">
  <link rel="stylesheet" type="text/css" as="style" href="/var/www/vhosts/pluginmarket.pbodev.info/src/css/style.css"
    all="media">
</head>

<body class="bg-light-color">


  <div class="wrapper">
    <!-- Header Start -->
    <header class="site-header fixed-top">
      <nav class="navbar navbar-expand-xl bg-white py-3 py-lg-0 shadow-sm">
        <div class="container">
          <a class="navbar-brand" href="#" aria-label="Market Place" title="Market Place">
            <img [src]="'assets/Images/appylogo.png'" alt="Market Place" width="160" height="40">
          </a>

          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <div class="d-flex align-items-center justify-content-between g-3 mb-3">
              <a class="navbar-brand d-lg-none" href="#" aria-label="Market Place" title="Market Place">
                <img src="images/logo.svg" alt="Market Place" width="160" height="40">
              </a>
              <button class="btn btn-light text-light closebtn-navbar d-block d-xl-none" type="button"><i
                  class="fa-close fa-lg fa-regular fa-fw"></i></button>
            </div>
            <ul class="navbar-nav mx-auto gap-lg-4">
              <li class="nav-item">
                <a routerLink="/start" title="Browse" class="nav-link active">Home</a>
              </li>
              <li class="nav-item">
                <a routerLink="/status" title="Manage" class="nav-link">Plugin Status</a>

              </li>

            </ul>
          </div>
          <div class="header-search-wrap d-none d-md-block">
            <ul class="d-flex gap-3">
              <li class="nav-item">
                <a href="#" class="nav-link text-black" title="Notification"><i class="fa-solid fa-bell"></i> <span
                    class="live"></span></a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle text-black" href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  My Profile
                </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/profile">Profile</a></li>
                  <li><a class="dropdown-item" (click)="logOut()">Log Out <i
                        class="fa-solid fa-right-from-bracket"></i></a></li>

                </ul>
              </li>
            </ul>
          </div>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
      <!-- /. nav end -->
    </header>
    <!-- /. Header End -->

    <!-- Main Start -->
    <main class="main-body ">

      <div class="container">
        <div class="row py-5">
          <div class="col-sm-12">
            <h2 class="large-headeing">Submit New Plugin</h2>

          </div>

        </div>
        <section class="form-wrap bg-white p-4 p-md-5 mb-5">
          <div class="row mb-4">
            <div class="col-md-2">
              <label for="pluginName">Plugin Name<span class="required">*</span></label>
            </div>
            <div class="col-md-10">
              <input type="text" class="form-control" placeholder="" name="pluginName" [(ngModel)]="pluginName"
                (input)="clearError('pluginName')">
              <div *ngIf="formError.pluginName" class="required">{{ formError.pluginName }}</div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-2">
              <label for="pluginCategory">Plugin Category<span class="required">*</span></label>
            </div>
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-8 mb-4 mb-md-0">
                  <select id="pluginCategory" class="form-select" name="pluginCategory" [(ngModel)]="pluginCategory"
                    (input)="clearError('pluginCategory')">
                    <option *ngFor="let data of getCategoriesData" [value]="data.categoryName">
                      {{ data.categoryName }}
                    </option>
                  </select>
                </div>
                <div *ngIf="formError.pluginCategory" class="required">{{ formError.pluginCategory }}</div>
              </div>
            </div>
          </div>


          <div class="row mb-4">

            <div class="col-md-2">
              <label>Platform<span class="required">*</span></label>
            </div>


            <label class="col-md-2">
              <input type="radio" name="option" [(ngModel)]="platFormType" value="Android"
                (change)="clearError('platFormType')">
              Android
            </label>
            <label class="col-md-2">
              <input type="radio" name="option" [(ngModel)]="platFormType" value="Ios"
                (change)="clearError('platFormType')">
              iOS
            </label>
            <label class="col-md-2">
              <input type="radio" name="option" [(ngModel)]="platFormType" value="Both"
                (change)="clearError('platFormType')">
              Both
            </label>

            <label class="col-md-2">
              <div *ngIf="formError.platFormType" class="required">{{ formError.platFormType }}</div>
            </label>



          </div>

          <!-- android uploader -->

     
          <div class="upload-wrapper mb-2" *ngIf="platFormType === 'Android' || platFormType === 'Both'">
            <input type="file" name="androidFileUrl" (change)="onPluginFileSelected($event, 'android')" class="upload" id="uploadPluginFile" accept=".zip" style="display: none;">
            <label for="uploadPluginFile" class="content-wrap" *ngIf="!androidFileUrl">
              <i class="fa fa-cloud-upload" aria-hidden="true"></i>
              <p>
                Drag & Drop Android File here or
                <span class="text-primary">Browse File</span>
              </p>
            </label>
            <div class="uploaded-file" *ngIf="androidFileUrl">
              <i class="fa fa-file-archive" aria-hidden="true"></i>
              {{ androidFileName }}
              <button type="button" class="btn btn-danger btn-sm" (click)="removeFile('android')">×</button>
            </div>
          </div>
          
        

          <!-- Ios Uploader -->

        
            <div class="upload-wrapper mb-2" *ngIf="platFormType === 'Ios' || platFormType === 'Both'">
              <input type="file" name="iOSFileUrl" (change)="onPluginFileSelected($event,'ios')" class="upload"
                id="uploadPluginFile" accept=".zip">
              <div class="content-wrap" *ngIf="!iOSFileUrl">
                <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                <p>
                  Drag & Drop iOS File here or
                  <span class="text-primary">Browse File</span>
                </p>
              </div>
              <div class="uploaded-file" *ngIf="iOSFileUrl">
                <i class="fa fa-file-archive" aria-hidden="true"></i>
              {{ iosFileName }}
                <button type="button" class="btn btn-danger btn-sm" (click)="removeFile('ios')">×</button>
              </div>
            </div>
    



          <div class="row mb-4">
            <div class="col-md-2 mb-2 mb-md-0">
              <label for="uploadPluginFile">Plugin Icon<span class="required">*</span></label>
            </div>
            <div class="col-md-10">
              <div class="upload-wrapper mb-2">
                <input type="file" name="iconUrl" (change)="onPluginFileSelected($event,'icon')" class="upload"
                  id="uploadPluginFile" accept=".jpg, .jpeg, .png">
                <div class="content-wrap" *ngIf="!iconUrl">
                  <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                  <p>
                    Drag & Drop image here or
                    <span class="text-primary">Browse File</span>
                  </p>
                </div>
                <div class="uploaded-file" *ngIf="iconUrl">
                  <img [src]="iconPreview" alt="Uploaded Icon" class="img-thumbnail" width="100">
                  <button type="button" class="btn btn-danger btn-sm" (click)="removeFile('icon')">×</button>
                </div>
              </div>
            </div>
          </div>


          <div class="row mb-4">
            <div class="col-md-2">
              <label for="pluginDescription">Description</label>
            </div>
            <div class="col-md-10">
              <textarea class="form-control" name="pluginDescription" id="" cols="30" rows="10"
                [(ngModel)]="pluginDescription"></textarea>
            </div>
          </div>
        </section>
        <div class="text-end mb-4">
          <button class="btn btn-primary mx-auto fw-bold buynow-btn py-2 px-5" (click)="validateForm()">Save
            Changes</button>
        </div>
      </div>






      <!-- /. Recommended section end -->

    </main>
    <!-- /. Main End -->
    <!-- footer start -->
    <footer class="site-footer bg-white py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <a href="#" class="mb-4 d-inline-block">
              <img src="images/logo.svg" alt="Market Place" width="160" height="40">
            </a>
          </div>
          <div class="col-md-2">
            <h3>Browse</h3>
            <ul>
              <li><a href="#">Most Popular</a></li>
              <li><a href="#">Essential Apps</a></li>
            </ul>
          </div>
          <div class="col-md-2">
            <h3>Developers</h3>
            <ul>
              <li><a href="#">Documentation</a></li>
              <li><a href="#">Getting Started </a></li>
              <li><a href="#">API Reference
                </a></li>
              <li><a href="#">Sample Code</a></li>
            </ul>
          </div>
          <div class="col-md-2">
            <h3>Company</h3>
            <ul>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">Blog</a></li>
            </ul>
          </div>
          <div class="col-md-3">
            <ul class="social-icon">
              <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
              <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
    <!-- /. footer End -->


    <!-- Modal HTML -->
    <div class="modal fade page-modal" #publishModal tabindex="-1" aria-labelledby="publishModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-body p-4 px-5 text-center">
            <div class="text-end mb-3">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <h2 class="large-heading mb-4">Publish This Plugin To The Marketplace Now?</h2>
            <button class="btn btn-primary px-5" (click)="onSubmit('Pending')">Publish</button>
            <div class="or-divider py-4"><span>OR</span></div>
            <p>
              <small>You Can Keep This Plugin As a Draft<br>
                <a class="fw-bold" (click)="onSubmit('Draft')" style="cursor: pointer;">Save as Draft</a>
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>




    <!-- Modal -->

  </div>
  <script src="js/custom.js"></script>

</body>