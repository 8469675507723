<div class="register-container">
  <h2>Register</h2>
  <div class="success-message" *ngIf="successMessage">{{ successMessage }}</div>
  <form (ngSubmit)="onRegister()" class="registration-form" #registerForm="ngForm">
    <div class="form-group">
      <label for="username">Email</label>
      <input type="text" id="username" name="username" [(ngModel)]="username" required #usernameInput="ngModel">
      <div *ngIf="usernameInput.invalid && (usernameInput.dirty || usernameInput.touched)" class="error-message">
        The username is required.
      </div>
    </div>
    <div class="form-group">
      <label for="password">Password</label>
      <input type="password" id="password" name="password" [(ngModel)]="password" required #passwordInput="ngModel">
      <div *ngIf="passwordInput.invalid && (passwordInput.dirty || passwordInput.touched)" class="error-message">
        The password is required.
      </div>
    </div>
    <button type="submit" class="register-button" [disabled]="registerForm.invalid">Register</button>
  </form>
</div>
