
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from '../cookie.service';
import { SessionService } from '../session.service';
import { ApiService } from 'src/api.service';
import { ToastService } from '../toast.service';
import { Component, ElementRef, ViewChild } from '@angular/core';
import * as bootstrap from 'bootstrap';
import { LoadingService } from '../loading.service'

@Component({
  selector: 'app-plugin-submission',
  templateUrl: './plugin-submission.component.html',
  styleUrls: ['./plugin-submission.component.css']
})
export class PluginSubmissionComponent {

  @ViewChild('publishModal') publishModal!: ElementRef;

  pluginName: string = '';
  pluginSubCategory: string = ''
  pluginCategory: string = '';
  pluginDescription: string = '';
  iconUrl: File | null = null;
  iconPreview: string | ArrayBuffer | null = null;
  pluginFileUrl: File | undefined;
  platFormType: string = '';
  androidFileUrl: File | null = null;
  androidFilePreview: string | ArrayBuffer | null = null;
  iOSFileUrl: File | null = null;
  iosFilePreview: string | ArrayBuffer | null = null;
  getCategoriesData: any = [];
  formError = {
    pluginName: '',
    pluginCategory: '',
    platFormType: '',
    iOSFileUrl: '',
    androidFileUrl: '',
    iconUrl: ''
  };

  androidFileName: string = '';
  iosFileName: string = '';

  isValid: boolean = false;
  formData: any
  constructor(private http: HttpClient, private cookies: CookieService, private router: Router, private session: SessionService, private api: ApiService, private toastr: ToastService, private loading: LoadingService) { }
  ngOnInit() {
    // this.cookies.authservice();
    this.getCategories();
  }

  onPluginFileSelected(event: Event, type: string): void {

    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (type === 'icon') {
          this.iconUrl = file;
          this.iconPreview = reader.result;
        } else if (type === 'android') {
          this.androidFileUrl = file;
          this.androidFileName = file.name;
          this.androidFilePreview = reader.result;
        } else if (type === 'ios') {
          this.iOSFileUrl = file;
          this.iosFileName = file.name;
          this.iosFilePreview = reader.result;
        }
      };
      reader.readAsDataURL(file);
    }
  }
  removeFile(type: string): void {

    if (type === 'icon') {
      this.iconUrl = null;
      this.iconPreview = null;
    }
    if (type == 'android') {
      this.androidFileUrl = null;
      this.androidFilePreview = null;
      this.androidFileName = ''
    }
    if (type == 'ios') {
      this.iOSFileUrl = null;
      this.iosFilePreview = null;
      this.iosFileName = '';
    }
  }

  validateForm() {

 

    this.formData = new FormData();

    if (!this.pluginName.trim()) {
      this.formError.pluginName = 'Plugin name is required';
    }
    if (!this.pluginCategory.trim()) {
      this.formError.pluginCategory = 'Plugin Category is required';
    }
    if (!this.platFormType.trim()) {
      this.formError.platFormType = 'Platform is required';
    }

    this.formData.append('pluginName', this.pluginName);
    this.formData.append('pluginCategory', this.pluginCategory);
    this.formData.append('platFormType', this.platFormType);
    if(this.pluginDescription.trim()!==''){
      this.formData.append('pluginDescription', this.pluginDescription);
    }

    if (this.iconUrl) {
      this.formData.append('iconUrl', this.iconUrl);
      this.formError.iconUrl = '';
    } else {
      this.toastr.showError('Please Select Plugin Icon');
      this.formError.iconUrl = 'plugin icon is required';
    }

    if (this.platFormType === 'Android') {
      if (this.androidFileUrl) {
        this.formData.append('androidFileUrl', this.androidFileUrl);
        this.formError.androidFileUrl = '';
        this.formError.iOSFileUrl = '';

      } else {
        this.toastr.showError('Please Select Android File');
        this.formError.androidFileUrl = 'Android File is required';

      }
    }
    if (this.platFormType === 'Ios') {
      if (this.iOSFileUrl) {
        this.formData.append('iOSFileUrl', this.iOSFileUrl);
        this.formError.iOSFileUrl = '';
        this.formError.androidFileUrl = '';
      } else {
        this.toastr.showError('Please Select iOS File');
        this.formError.iOSFileUrl = 'iOS File is required';
      }

    }
    if (this.platFormType === 'Both') {
      if (this.androidFileUrl) {
        this.formData.append('androidFileUrl', this.androidFileUrl);
        this.formError.androidFileUrl = '';

      } else {
        this.toastr.showError('Please Select Android File');
        this.formError.androidFileUrl = 'Android File is required';

      }
      if (this.iOSFileUrl) {
        this.formData.append('iOSFileUrl', this.iOSFileUrl);
        this.formError.iOSFileUrl = '';
      } else {
        this.toastr.showError('Please Select iOS File');
        this.formError.iOSFileUrl = 'iOS File is required';

      }
    }



    this.isValid = !Object.values(this.formError).some(error => error !== '');


    if (this.isValid) {
      this.openModal();
    }

  }

  onSubmit(pluginStatus?: string) {
    this.loading.show();
    this.formData.append('pluginStatus', pluginStatus);
    this.api.post('submitPlugin', this.formData).subscribe(
      (response: any) => {        
        if (response.status === 1) {
          this.toastr.showSuccess(response.msg);
          this.loading.hide();
          this.router.navigate(['/status']);
        } else {
          this.toastr.showError(response.msg);
          this.loading.hide();
        }


      },
      (err) => {

        if (err.status === 400) {
          this.toastr.showError(err.error.msg);
          this.loading.hide();
        } else {
          this.toastr.showError("We're experiencing technical difficulties. Please check your internet connection and try again later.");
          this.loading.hide();

        }

      }
    );
    this.closeModel();


  }

  openModal(): void {
    const modal = new bootstrap.Modal(this.publishModal.nativeElement);
    modal.show();
  }
  closeModel(): void {
    if (this.publishModal) {
      const modal = bootstrap.Modal.getInstance(this.publishModal.nativeElement);
      if (modal) {
        modal.hide();
      } else {
        console.log('No modal instance found');
      }
    }
  }

  clearError(fieldName: keyof typeof this.formError) {
    this.formError[fieldName] = '';
  }

  checkLoading():void{
    this.loading.show();
  }


  logOut() {
    this.cookies.deleteAuthCookies();
    this.session.logOut();
    window.location.href = 'https://commonlogin.pbodev.info/login';
  }

  async getCategories(): Promise<any> {
    this.api.post('getCategories').subscribe((response: any) => {
      this.getCategoriesData = response.data;
    }, (error) => {
      console.error('Error submitting form:', error);
    }
    )
  }




}
