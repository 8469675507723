<body>
    <header>
      <img class="logo" [src]="'assets/Images/download.jpeg'" alt="Logo">
      <h1>Appy Pie Plugin Marketplace</h1>
  
      <nav>
        <ul>
          <li><a href="/plugin">Home</a></li>
          <li><a href="/pluginlist">Submitted Plugins</a></li>
          <li><a href="https://www.appypie.com/about-us">About Us</a></li>
          <li><a href="https://www.appypie.com/contact-us">Contact Us</a></li>
          <li><a href="/admin">Admin</a></li>
        </ul>
      </nav>
    </header>
  
    <section class="main-content">
  
      <p>Find and discover the latest plugins for your needs.</p>
      <div style="float: right;">
        <div>
          <!-- <h3> List Your APP</h3>
          <p>Register as an app developer and submit your app easily with our App Store</p> -->
          <a href="/home" class="btn">Get Started as an App Developer</a>
        </div>
      </div>
      <!-- Display a search bar to search for plugins -->
      <h3>Search for Plugins</h3>
      <form>
        <input type="text" name="query" [(ngModel)]="searchQuery" placeholder="Search for plugins">
        <button type="button" class="btn" (click)="filterPlugins()">Search</button>
      </form>
  
      <!-- Display a grid of approved plugins -->
      <div class="plugin-grid">
        <div *ngFor="let plugin of filteredPlugins" class="plugin">
          <img width="30" src="https://png.pngtree.com/png-clipart/20221018/ourmid/pngtree-facebook-icon-social-media-3d-png-image_6308418.png" alt="">
          <h4>{{ plugin.pluginName }}</h4>
          <p>{{ plugin.pluginDescription }}</p>
          <!-- <img [src]="plugin.selectedFile" alt="Plugin Image" /> -->
          <a [routerLink]="'/plugindetails/' + plugin.pluginid" class="btn">Configure</a>
        </div>
      </div>
    </section>
  
    <footer>
      <p>&copy; 2023 Plugin Marketplace</p>
    </footer>
  </body>
  