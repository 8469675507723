import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '../cookie.service';
import { Router } from '@angular/router';
import { SessionService } from '../session.service';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'app-plugin-details',
  templateUrl: './plugin-details.component.html',
  styleUrls: ['./plugin-details.component.css']
})
export class PluginDetailsComponent {
  plugin: any;
  showDescription: boolean = false;
  showInstallation: boolean = false;

  

  constructor(private route: ActivatedRoute, private http: HttpClient,private cookies:CookieService, private session:SessionService, private api:ApiService) { }

  ngOnInit(): void {
    // this.cookies.authservice();
    this.route.params.subscribe(params => {
      console.log("I am starting");
      const pluginid = params['pluginid'];
      this.api.get(`form-submissions/${pluginid}`).subscribe(
        (data: any) => {
          
        
          this.plugin = data; 
          
        },
        (error) => {
          console.error("Error in HTTP request:", error);
        }
      );
    });
  }

  logout() { 
    console.log("I am getting details log out");
    this.session.logOut();
    this.cookies.deleteAuthCookies();
    window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}/plugin`;
  }
}
