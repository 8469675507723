import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Observable, timer } from 'rxjs';
import { switchMap, filter, startWith } from 'rxjs/operators';
import { ApiService } from 'src/api.service';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {

  private readonly TIMEOUT = 15 * 60 * 1000; // 15 minutes in milliseconds
  private readonly CHECK_INTERVAL = 1 * 60 * 1000; // Check every 1 minute

  constructor(private router: Router, private ngZone: NgZone, private api: ApiService, private cookie: CookieService) {
    this.setupTimeoutChecker();
  }

  private setupTimeoutChecker() {
    // Track user activity events
    const events$: Observable<Event> = fromEvent<Event>(document, 'mousemove').pipe(
      filter(event => event !== null) // Filter out null values, just a safety check
    );

    // Start tracking inactivity timer
    const inactivityTimer$: Observable<number> = timer(this.TIMEOUT);

    // Switch to inactivity timer when activity is detected
    const inactivity$: Observable<number> = events$.pipe(
      switchMap(() => inactivityTimer$)
    );

    // Redirect to login page on inactivity timeout
    inactivity$.subscribe(() => {
      this.ngZone.run(() => {
        // this.cookie.deleteCookie('APPYID');
        // this.cookie.deleteCookie('keydata');
        // this.cookie.deleteCookie('79fefaf2564aabb19d44fe324844c86b');
        // window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}`;
      });
    });

    // Optionally, you can use a timer to perform periodic checks or updates
    // This is an example and may be removed if not needed
    timer(0, this.CHECK_INTERVAL).subscribe(() => {
      // Periodic check logic, if required
    });
  }
}
