import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userData: any;

  constructor() {
    this.userData = {};
  }

  async fetchUserDetails(keydata: string): Promise<any> {
    try {
      const response = await axios.post('https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/payment_encryption', {
        action: 'decrypt',
        string: keydata,
      });

      if (response && response.data) {
        this.userData = response.data;
        return this.userData;
      } else {
        console.error('Error decrypting key data');
        return null;
      }
    } catch (error) {
      console.error('Error decrypting key data:', error);
      return null;
    }
  }

  getEmail(): string | null {
    return this.userData.email || null;
  }

  getUserId(): string | null {
    return this.userData.id || null;
  }

  getLoginType(): string | null {
    return this.userData.login_type || null;
  }
}
