import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CookieService } from './cookie.service';
import { SessionService } from './session.service';
import axios from 'axios';
import { ApiService } from 'src/api.service';
import { timeout, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private cookies: CookieService, private session: SessionService, private api: ApiService) { }

  async canActivate(): Promise<boolean> {

    var APPYID = this.cookies.getAuthCookies('APPYID');
    var loginStatus = this.cookies.getAuthCookies('79fefaf2564aabb19d44fe324844c86b');
    var keydata = this.cookies.getAuthCookies('keydata');
    const isFirstTime = localStorage.getItem('isFirstTime') === null;

    //=======for local to get token=====//
    
    loginStatus = '1';
    APPYID = '6b7b4b4db62209d8e17e7155ac15efe4';
    keydata = 'localhost'

    await this.setToken(APPYID);
    console.log('Token Set Successfully');


    if (loginStatus === '1') {
      if (keydata) {
        console.log("I am an existing user");
        this.router.navigate(['/start']);
        return true;
      } else {
        // Existing user but missing required data, handle this case as needed
        console.log("Existing user, but missing data");
        // You might want to redirect to an error page or handle this case differently.
      }
    } else {
      // User needs to authenticate
      if (isFirstTime) {
        // First-time user, set the flag to indicate first-time
        localStorage.setItem('isFirstTime', 'true');
      }
      // Redirect to the third-party login page
      window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}`;

    }

    return false;
  }

  async setToken(APPYID: string): Promise<any> {

    try {
      const response = await this.api.post('genrateJwtToken', { file_name: APPYID })
        .pipe(
          timeout(5000),
          catchError((error) => {
            console.error('Request failed or timed out:', error);
            window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}`;
            return throwError(error);
          })
        )
        .toPromise();
      this.cookies.setCookie('authToken', response.token, 1);

    } catch (error) {
      console.error('Error in setToken:', error);
      window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}`;
    }
  }
}
