import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  formData: any[] = [];

  constructor(private http: HttpClient,private api: ApiService) {}

  ngOnInit(): void {
    this.fetchFormData();
  }

  fetchFormData() {
    this.api.get('form-submissions').subscribe(
      (response : any[]) => {
        this.formData = response;
        // console.log("Form Data retrieved from the server:", this.formData);
      },
      (error) => {
        console.error('Error fetching form data:', error);
      }
    );
  }
  

  approve(data: any) {
    this.api.post('approve', { id: data._id }).subscribe(
      () => {
        this.fetchFormData();
      },
      (error) => {
        console.error('Error approving form data:', error);
      }
    );
  }

  reject(data: any) {
    this.api.post('reject', { id: data._id }).subscribe(
      () => {
        this.fetchFormData();
      },
      (error) => {
        console.error('Error rejecting form data:', error);
      }
    );
  }
}
