import { Component } from '@angular/core';
import { Router } from '@angular/router'; 
import { IdleTimeoutService } from './idle-timeout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'weather-plugin-app';

  constructor(private router: Router,private idleTimeoutService: IdleTimeoutService) {} 

  navigateToAdminPage() {
    this.router.navigate(['/admin']); 
  }
}
