import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '../cookie.service';
import { SessionService } from '../session.service';
import { ApiService } from 'src/api.service';


@Component({
  selector: 'app-plugin-status',
  templateUrl: './plugin-status.component.html',
  styleUrls: ['./plugin-status.component.css']
})
export class PluginStatusComponent {
  submittedPlugins: any[] = [];

  constructor(private router: Router, private http: HttpClient, private cookies:CookieService, private session:SessionService,private api:ApiService) {}

  ngOnInit(): void {
    // this.cookies.authservice();
    this.fetchSubmittedPlugins();
  }

  fetchSubmittedPlugins() {
    this.api.get('form-submissions').subscribe(
      (response:any[]) => {
        this.submittedPlugins = response;
      },
      (error) => {
        console.error('Error fetching submitted plugins:', error);
      }
    );
  }

  goToDraft(plugin: any) {
    this.router.navigate([`draft/${plugin}`]);
  }

logOut() {
    this.cookies.deleteAuthCookies();
    this.session.logOut();
    window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}`;
  }


  publishPlugin(plugin: any) {
    this.api.post(`publish/${plugin.pluginid}`, {}).subscribe(
      (response:any) => {
        console.log('Plugin published successfully:', response);
        // Refresh the list of submitted plugins after publishing
        this.fetchSubmittedPlugins();
      },
      (error) => {
        console.error('Error publishing plugin:', error);
      }
    );
  }

  deletePlugin(plugin: any) {
    this.api.post(`delete/${plugin.pluginid}`,{}).subscribe(
      () => {
        console.log('Plugin deleted successfully.');
        // Remove the deleted plugin from the list
        this.submittedPlugins = this.submittedPlugins.filter((p) => p.pluginid !== plugin.pluginid);
      },
      (error) => {
        console.error('Error deleting plugin:', error);
      }
    );
  }


  formatDate(date: string): string {
    return new Date(date).toLocaleDateString();
  }
  
}
