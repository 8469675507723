
  <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="" content="" />
    <title>Market Place</title>

    <link
      rel="stylesheet"
      as="style"
      onload="this.onload=null;this.rel='stylesheet'"
      href="https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css"
    />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="" />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800;900&amp;family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,500&amp;display=swap"
      rel="stylesheet"
    />
    <!-- js files -->
    <script src="src/js/jquery.min.3.2.js"></script>
    <script src="src/js/bootstrap.bundle.min.js"></script>

    <!-- css files -->
    <link rel="stylesheet" type="text/css" href="/Users/ramjilal/Desktop/Weather-Plugin/Plugin Market/src/css/style.css">
    <link rel="stylesheet" type="text/css" as="style" href="/Users/ramjilal/Desktop/Weather-Plugin/Plugin Market/src/css/style.css" all="media">
  </head>
  <body class="bg-light-color">
    <div class="wrapper">
      <!-- Header Start -->
      <header class="site-header fixed-top">
        <nav class="navbar navbar-expand-xl bg-white py-3 py-lg-0 shadow-sm">
          <div class="container">
            <a
              class="navbar-brand"
              href="#"
              aria-label="Market Place"
              title="Market Place"
            >
              <img
              [src]="'assets/Images/appylogo.png'"
                alt="Market Place"
                width="160"
                height="40"
              />
            </a>

            <div class="collapse navbar-collapse" id="navbarNavDropdown">
              <div
                class="d-flex align-items-center justify-content-between g-3 mb-3"
              >
                <a
                  class="navbar-brand d-lg-none"
                  href="#"
                  aria-label="Market Place"
                  title="Market Place"
                >
                  <img
                    src="images/logo.svg"
                    alt="Market Place"
                    width="160"
                    height="40"
                  />
                </a>
                <button
                  class="btn btn-light text-light closebtn-navbar d-block d-xl-none"
                  type="button"
                >
                  <i class="fa-close fa-lg fa-regular fa-fw"></i>
                </button>
              </div>
              <ul class="navbar-nav mx-auto gap-lg-4">
                <li class="nav-item">
                  <a routerLink="/start" title="Browse" class="nav-link active">Home</a>
                </li>
                <li class="nav-item">
                  <a routerLink="/manage" title="Manage" class="nav-link">Plugins</a>
                </li>
              </ul>
            </div>
            <div class="header-search-wrap d-none d-md-block">
                        <ul class="d-flex gap-3">
                            <li class="nav-item">
                                <a href="#" class="nav-link text-black" title="Notification"><i class="fa-solid fa-bell"></i> <span class="live"></span></a>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle text-black" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  My Profile
                                </a>
                                <ul class="dropdown-menu">
                                    <!-- <li><a class="dropdown-item"  routerLink="/profile">Profile</a></li> -->
                                  <li><a class="dropdown-item" (click)="logOut()">Log Out <i class="fa-solid fa-right-from-bracket"></i></a></li>
                                  
                                </ul>
                            </li>
                        </ul>
                    </div>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
        <!-- /. nav end -->
      </header>
      <!-- /. Header End -->

      <!-- Main Start -->
      <main class="main-body">
        <div class="container">
          <div class="row py-5">
            <div class="col-sm-12">
              <h2 class="large-headeing">My Profile</h2>
            </div>
          
          </div>
          <section class="bg-white form-wrap p-5 mb-4">
            <div class="row">
              <div class="col-md-3 mb-3 mb-md-0">
                <div class="nav flex-column nav-pills me-3 left-navs" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button class="nav-link active" id="v-pills-home-tab" (click)="redirectToExternalLink('https://commonlogin.pbodev.info/home')" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Profile</button>
                  <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Payouts</button>
                  <button class="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Payment</button>
                  <button class="nav-link" routerLink="/status" type="button" role="tab" aria-controls="v-pills-status" aria-selected="false">My Apps</button>
                  <button class="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Transaction</button>
                </div>
              </div>
              <div class="col-md-9">
                <div class="tab-content" id="v-pills-tabContent">
                  
                  
                  <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">...</div>
                  <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">...</div>
                </div>
              </div>
            </div>
           
           
          </section>
          <div class="text-end mb-4">
            <button class="btn btn-primary mx-auto fw-bold buynow-btn py-2 px-5">Update</button>
          </div>
        </div>

        <!-- /. Recommended section end -->
      </main>
      <!-- /. Main End -->
      <!-- footer start -->
      <footer class="site-footer bg-white py-5">
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <a href="#" class="mb-4 d-inline-block">
                <img
                  src="images/logo.svg"
                  alt="Market Place"
                  width="160"
                  height="40"
                />
              </a>
            </div>
            <div class="col-md-2">
              <h3>Browse</h3>
              <ul>
                <li><a href="#">Most Popular</a></li>
                <li><a href="#">Essential Apps</a></li>
              </ul>
            </div>
            <div class="col-md-2">
              <h3>Developers</h3>
              <ul>
                <li><a href="#">Documentation</a></li>
                <li><a href="#">Getting Started </a></li>
                <li><a href="#">API Reference </a></li>
                <li><a href="#">Sample Code</a></li>
              </ul>
            </div>
            <div class="col-md-2">
              <h3>Company</h3>
              <ul>
                <li><a href="#">Contact Us</a></li>
                <li><a href="#">Blog</a></li>
              </ul>
            </div>
            <div class="col-md-3">
              <ul class="social-icon">
                <li>
                  <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-brands fa-twitter"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <!-- /. footer End -->
    </div>
    <script src="js/custom.js"></script>
  </body>

