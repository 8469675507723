import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '../cookie.service';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'app-plugin-list',
  templateUrl: './plugin-list.component.html',
  styleUrls: ['./plugin-list.component.css'],
})
export class PluginListComponent implements OnInit {
  submittedPlugins: any[] = [];

  constructor(private http: HttpClient, private cookies:CookieService,private api:ApiService) {}

  ngOnInit(): void {
    this.fetchSubmittedPlugins();
    const mobileVerified = this.cookies.getAuthCookies('mobile_verified');
    const emailVerified = this.cookies.getAuthCookies('email_verified');
    const keydata = this.cookies.getAuthCookies('keydata');
    const appyid = this.cookies.getAuthCookies('APPYID');
    const loginby = this.cookies.getAuthCookies('login_by');
    console.log("I am email verified", emailVerified);
    console.log("I am Key Data ", keydata);
    console.log("I am mobile verified", mobileVerified);
    console.log("I am APPYID", appyid);
    console.log("I am APPYID", loginby);
  }

  fetchSubmittedPlugins() {
    this.api.get('form-submissions').subscribe(
      (response:any[]) => {
        this.submittedPlugins = response;
      },
      (error) => {
        console.error('Error fetching submitted plugins:', error);
      }
    );
  }
}
