import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; 
import { HttpClientModule } from '@angular/common/http'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PluginFormComponent } from './plugin-form/plugin-form.component';
import { AdminComponent } from './admin/admin.component';
import { PluginListComponent } from './plugin-list/plugin-list.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './registration/registration.component';
import { ToastrModule } from 'ngx-toastr';
import { PluginsComponent } from './plugins/plugins.component';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { PlugindetailsComponent } from './plugindetails/plugindetails.component';
import { AuthGuard } from './auth.guard';
import { ApiService } from 'src/api.service';
import { CookieService } from './cookie.service';
import { HomeComponent } from './home/home.component';
import { PluginDetailsComponent } from './plugin-details/plugin-details.component';
import { PluginDisplayComponent } from './plugin-display/plugin-display.component';
import { PluginManageComponent } from './plugin-manage/plugin-manage.component';
import { PluginDraftComponent } from './plugin-draft/plugin-draft.component';
import { PluginStatusComponent } from './plugin-status/plugin-status.component';
import { PluginSubmissionComponent } from './plugin-submission/plugin-submission.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { LoadingComponent } from './loading/loading.component';

@NgModule({
  declarations: [
    AppComponent,
    PluginFormComponent,
    AdminComponent,
    PluginListComponent,
    LoginComponent,
    RegisterComponent,
    PluginsComponent,
    GuidelinesComponent,
    PlugindetailsComponent,
    HomeComponent,
    PluginDetailsComponent,
    PluginDisplayComponent,
    PluginManageComponent,
    PluginDraftComponent,
    PluginStatusComponent,
    PluginSubmissionComponent,
    UserProfileComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
  FormsModule,
  HttpClientModule,
  BrowserAnimationsModule,
  ToastrModule.forRoot(),
  AppRoutingModule,
  MatDialogModule,
  MatButtonModule,

  ],
  providers: [AuthGuard,CookieService,ApiService,{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})

export class AppModule { }
