

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="" content="">
    <title>Market Place</title>

    <link rel="preload" as="style" onload="this.onload=null;this.rel='stylesheet'" href="https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,500&display=swap" rel="stylesheet">
    <!-- js files -->
    <script src="src/js/jquery.min.3.2.js"></script>
    <script src="src/js/bootstrap.bundle.min.js"></script>

    <!-- css files -->
    <link rel="stylesheet" type="text/css" href="/Users/ramjilal/Desktop/Weather-Plugin/Plugin Market/src/css/style.css">
    <link rel="stylesheet" type="text/css" as="style" href="/Users/ramjilal/Desktop/Weather-Plugin/Plugin Market/src/css/style.css" all="media">
</head>
<body>
    <div class="wrapper">
        <!-- Header Start -->
        <header class="site-header fixed-top">
            <nav class="navbar navbar-expand-xl bg-white py-3 py-lg-0 shadow-sm">
                <div class="container">
                    <a class="navbar-brand" href="#" aria-label="Market Place" title="Market Place">
                        <img [src]="'assets/Images/appylogo.png'" alt="Market Place" width="160" height="40" />
                    </a>
                    
                    <div class="collapse navbar-collapse" id="navbarNavDropdown">
                        <div class="d-flex align-items-center justify-content-between g-3 mb-3">
                            <a class="navbar-brand d-lg-none" href="#" aria-label="Market Place" title="Market Place">
                                <img [src]="'assets/Images/logo.svg'" alt="Market Place" width="160" height="40" />
                            </a>
                            <button class="btn btn-light text-light closebtn-navbar d-block d-xl-none" type="button"><i class="fa-close fa-lg fa-regular fa-fw"></i></button>
                        </div>
                        <ul class="navbar-nav mx-auto gap-lg-4">
                            <li class="nav-item">
                                <a routerLink="/start" title="Browse" class="nav-link active">Home</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/manage" title="Manage" class="nav-link">Plugins</a>
                            </li>
                            
                        </ul>
                    </div>
                    <div class="header-search-wrap d-none d-md-block">
                        <ul class="d-flex gap-3">
                            <li class="nav-item">
                                <a href="#" class="nav-link text-black" title="Notification"><i class="fa-solid fa-bell"></i> <span class="live"></span></a>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle text-black" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  My Profile
                                </a>
                                <ul class="dropdown-menu">
                                  <li><a class="dropdown-item" routerLink="/profile">Profile</a></li>
                                  <li><a class="dropdown-item" (click)="logout()">Log Out <i class="fa-solid fa-right-from-bracket"></i></a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </div>
            </nav>
            <!-- /. nav end -->
        </header>
        <!-- /. Header End -->

        <!-- Main Start -->
        <main class="main-body">
            <section class="banner-sec py-5 bg-light-color">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <a routerLink="/manage" class="fw-medium mb-3 d-inline-block"> <i class="fa-regular fa-arrow-left"></i> Back to App Store</a>
                        </div>
                        <div class="col-lg-8">
                            <div class="inner-text">
                                <h1 class="fs-4 mb-3">{{ plugin.pluginName }}</h1>
                                <p>{{ plugin.pluginDescription }}</p>
                                <p>With this Application you can collaborate with teammates at any time, from any where in the world. Send an email, text message, start a video call or create a new project thread all in real time. All of your conversations will automatically sync across every device.</p>
                                <p>Team administrators can create public or private threads, add or remove team members and even invite people from external organizations to collaborate on a project.</p>
                            </div>
                            <div class="d-flex flex-wrap gap-4">
                                <ul class="categories-listgroup">
                                    <li class="fw-bold m-0 mb-1 text-black w-100">Categories:</li>
                                    <li><a href="#">{{ plugin.pluginCategory }}</a></li>
                                    <!-- <li><a href="#">Featured</a></li>
                                    <li><a href="#">Essential Apps</a></li> -->
                                </ul>
                                <ul class="developer-listgroup">
                                    <li class="fw-bold text-black w-100">Developer links:</li>
                                    <li><a href="#">Support</a></li>
                                    <li><a href="#">Terms of Service</a></li>
                                    <li><a href="#">Developer Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="align-items-end buynow-wrap d-flex flex-column justify-content-end text-end ms-md-auto">
                                <div class="buynow-icon">
                                    <span class="svgicon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="53.854" height="64" viewBox="0 0 53.854 64">
                                            <path id="Icon" d="M0,0H53.854V42.667L26.927,64,0,42.667Z" fill="#eeebff"/>
                                        </svg>                                                                                                                                           
                                    </span>
                                </div>
                                <a [href]="'https://72snappy.pbodev.info/utility/plugin-marketplace-app/?pluginId=' + plugin.pluginid" class="btn btn-primary mx-auto fw-bold buynow-btn py-2 w-100">Buy Now for $11.99</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Application section start -->
            <section class="application-sec py-5">
                <div class="container">
                    <div class="row justify-content-center py-lg-5">
                        <div class="col-lg-9">
                            <div class="media-item d-flex align-items-center flex-wrap gap-4 mb-4 border-bottom pb-4">
                                <div class="flex-shrink-0">
                                    <img [src]="'assets/Images/application_01.png'" alt="">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h2 class="fw-bold fs-6">Collaborate from Anywhere</h2>
                                    <p>Send an email, text message, start a video call or create a new project thread all in real time</p>
                                </div>
                            </div>
                            <div class="media-item d-flex align-items-center flex-wrap gap-4 mb-4 border-bottom pb-4">
                                <div class="flex-shrink-0">
                                    <img [src]="'assets/Images/application_02.png'" alt="">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h2 class="fw-bold fs-6">Powerful Administration </h2>
                                    <p>Team administrators can create public or provate threads and add or remove team members</p>
                                </div>
                            </div>
                            <div class="media-item d-flex align-items-center flex-wrap gap-4 mb-4 border-bottom pb-4">
                                <div class="flex-shrink-0">
                                    <img [src]="'assets/Images/application_03.png'" alt="">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h2 class="fw-bold fs-6">Contacts and Data</h2>
                                    <p>All of your important contact and data are automatically synced and available right from your UI</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- /. Application section end -->

            <!-- Recommended section start -->
            <section class="recommended-sec py-5 bg-light-color">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="text-center mb-4 fs-1">Recommended For You</h2>
                        </div>
                        <div class="col-md-4">
                            <div class="recommended-item bg-white shadow-light my-3 d-flex align-items-center">
                                <div class="recommended-icon bg-redlight">
                                    <span class="svgicon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="48" viewBox="0 0 44 48">
                                            <path id="Icon" d="M0,10.909,22,0,44,10.909V37.091L22,48,0,37.091Z" fill="#fedfdf"/>
                                        </svg>                                                                                       
                                    </span>
                                </div>
                                <div class="recommended-inner p-3">
                                    <h3 class="fs-6 fw-bold">Application</h3>
                                    <p class="small mb-0">Integrate directly with your account and make customer updates a breeze</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="recommended-item bg-white shadow-light my-3 d-flex align-items-center">
                                <div class="recommended-icon bg-purplelight">
                                    <span class="svgicon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                            <path id="Icon" d="M24,48A24,24,0,0,1,3.5,11.511,15.084,15.084,0,0,0,6.655,28.4a15.355,15.355,0,0,0,21.6,0,15.077,15.077,0,0,0,0-21.437,15.232,15.232,0,0,0-10.8-4.439A15.4,15.4,0,0,0,10.317,4.28,24,24,0,0,1,43.476,38.027a6.136,6.136,0,0,0,.16-1.395,6.55,6.55,0,1,0-6.546,6.315,6.759,6.759,0,0,0,2.031-.31A23.9,23.9,0,0,1,24,48Z" fill="#eeebff"/>
                                        </svg>
                                                                                                                                 
                                    </span>
                                </div>
                                <div class="recommended-inner p-3">
                                    <h3 class="fs-6 fw-bold">Add on</h3>
                                    <p class="small mb-0">Integrate directly with your account and make customer updates a breeze</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="recommended-item bg-white shadow-light my-3 d-flex align-items-center">
                                <div class="recommended-icon bg-greenlight">
                                    <span class="svgicon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                                            <path id="Icon" d="M0,0H48V37.091H19.79L0,48Z" fill="#ebf9ea"/>
                                        </svg>                                                                                                                                 
                                    </span>
                                </div>
                                <div class="recommended-inner p-3">
                                    <h3 class="fs-6 fw-bold">Integration</h3>
                                    <p class="small mb-0">Integrate directly with your account and make customer updates a breeze</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- /. Recommended section end -->

        </main>
        <!-- /. Main End -->
        <!-- footer start -->
        <footer class="site-footer bg-white py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <a href="#" class="mb-4 d-inline-block">
                            <img [src]="'assets/Images/logo.svg'" alt="Market Place" width="160" height="40" />
                        </a>
                    </div>
                    <div class="col-md-2">
                        <h3>Browse</h3>
                        <ul>
                            <li><a href="#">Most Popular</a></li>
                            <li><a href="#">Essential Apps</a></li>
                        </ul>
                    </div>
                    <div class="col-md-2">
                        <h3>Developers</h3>
                        <ul>
                            <li><a href="#">Documentation</a></li>
                            <li><a href="#">Getting Started </a></li>
                            <li><a href="#">API Reference
                            </a></li>
                            <li><a href="#">Sample Code</a></li>
                        </ul>
                    </div>
                    <div class="col-md-2">
                        <h3>Company</h3>
                        <ul>
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">Blog</a></li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <ul class="social-icon">
                            <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <!-- /. footer End -->
    </div>
    <script src="js/custom.js"></script>
</body>