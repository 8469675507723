<div class="login-container">
    <h2>Login</h2>
    <div class="success-message" *ngIf="successMessage">{{ successMessage }}</div>
    <form >
      <div class="form-group">
        <label for="username">Email</label>
        <input type="text" id="username" name="username" [(ngModel)]="username" required>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" id="password" name="password" [(ngModel)]="password" required>
      </div>
      <div class="login-button">
        <button class="btn btn-submit" (click)="onSubmit()">Login</button>
      </div>
    </form>
</div>
