import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'app-plugindetails',
  templateUrl: './plugindetails.component.html',
  styleUrls: ['./plugindetails.component.css']
})
export class PlugindetailsComponent implements OnInit {
  plugin: any;
  showDescription: boolean = false;
  showInstallation: boolean = false;

  

  constructor(private route: ActivatedRoute, private http: HttpClient,private api:ApiService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      console.log("I am starting");
      const pluginid = params['pluginid'];
      this.api.get(`form-submissions/${pluginid}`).subscribe(
        (data: any) => {
          
        
          this.plugin = data; 
          
        },
        (error) => {
          console.error("Error in HTTP request:", error);
        }
      );
    });
  }
  

  toggleDescription() {
    this.showDescription = !this.showDescription;
  }

  toggleInstallation() {
    this.showInstallation = !this.showInstallation;
  }


}
