

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="" content="">
    <title>Market Place</title>

    <link rel="preload" as="style" onload="this.onload=null;this.rel='stylesheet'" href="https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800;900&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,500&display=swap" rel="stylesheet">
    <!-- js files -->
    <script src="src/js/jquery.min.3.2.js"></script>
    <script src="src/js/bootstrap.bundle.min.js"></script>

    <!-- css files -->
    <link rel="stylesheet" type="text/css" href="/var/www/vhosts/pluginmarket.pbodev.info/src/css/style.css">
    <link rel="stylesheet" type="text/css" as="style" href="/var/www/vhosts/pluginmarket.pbodev.info/src/css/style.css" all="media">
</head>
<body>
    <div class="wrapper">
        <!-- Header Start -->
        <header class="site-header fixed-top">
            <nav class="navbar navbar-expand-xl bg-white py-3 py-lg-0 shadow-sm">
                <div class="container">
                    <a class="navbar-brand" href="#" aria-label="Market Place" title="Market Place">
                        <img [src]="'assets/Images/appylogo.png'" alt="Market Place" width="160" height="40" />
                    </a>
                    
                    <div class="collapse navbar-collapse" id="navbarNavDropdown">
                        <div class="d-flex align-items-center justify-content-between g-3 mb-3">
                            <a class="navbar-brand d-lg-none" href="#" aria-label="Market Place" title="Market Place">
                                <img [src]="'assets/Images/logo.svg'" alt="Market Place" width="160" height="40" />
                            </a>
                            <button class="btn btn-light text-light closebtn-navbar d-block d-xl-none" type="button"><i class="fa-close fa-lg fa-regular fa-fw"></i></button>
                        </div>
                        <ul class="navbar-nav mx-auto gap-lg-4">
                            <li class="nav-item">
                                <a routerLink="/start" title="Browse" class="nav-link active">Home</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/manage" title="Manage" class="nav-link">Plugins</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/start" title="Search" class="nav-link text-black"><i class="fa-regular fa-magnifying-glass"></i> Search</a>
                            </li>
                        </ul>
                    </div>
                    <div class="header-search-wrap d-none d-md-block">
                        <ul class="d-flex gap-3">
                            <li class="nav-item">
                                <a routerLink="/profile" class="btn btn-primary fw-semibold py-2" aria-label="Add Post" title="Add Post">My Profile</a>
                            </li>
                            <li class="nav-item">
                                <a href="https://commonlogin.pbodev.info/login?frompage=https:%2F%2Fpluginmarket.pbodev.info%2Fstart" class="btn btn-outline-primary fw-semibold py-2" aria-label="Add Post" title="Add Post">Log In</a>
                            </li>
                        </ul>
                    </div>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </div>
            </nav>
            <!-- /. nav end -->
        </header>
        <!-- /. Header End -->

        <!-- Main Start -->
        <main class="main-body">
            <section class="banner-sec py-5 bg-light-color">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner-text mb-md-5">
                                <h1>Appypie Plugin Platform</h1>
                                <p>A design template for implementing your app store with OpenChannel</p>
                            </div>
                            <form class="form-search">
                                <div class="input-group">
                                    <input class="form-control border" type="search" placeholder="Search" id="search">
                                    <span class="input-group-append">
                                        <button class="btn bg-white border-0 h-100 ms-n5" type="button">
                                            <i class="fa-regular fa-magnifying-glass fa-fw fa-lg"></i>
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-6">
                           <div class="banner-img text-center">
                                <img [src]="'assets/Images/banner.svg'" alt="" width="529" height="529">
                           </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="collections-sec py-5 bg-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 col-lg-3">
                            <!-- <div class="collections-list mb-5">
                                <h2>Collections</h2>
                                <ul>
                                    <li><a href="#">Featured</a></li>
                                    <li><a href="#">Most Popular</a></li>
                                    <li><a href="#">Essential Apps</a></li>
                                    <li><a href="#">My Apps</a></li>
                                </ul>
                            </div>
                            <div class="collections-list mb-5">
                                <h2>Works with</h2>
                                <ul>
                                    <li><a href="#">Web Products</a></li>
                                    <li><a href="#" class="active">On Premise</a></li>
                                    <li><a href="#">Virtual Environments</a></li>
                                    <li><a href="#">Infrastructure</a></li>
                                </ul>
                            </div> -->
                            <div class="collections-list mb-5">
                                <h2>Categories</h2>
                                <ul>
                                    <li><a  (click)="filterByCategory('All')">All Apps</a></li>
                                    <li><a  (click)="filterByCategory('Entertainment')">Entertainment</a><li>
                                    <li><a  (click)="filterByCategory('socialnetwork')">Social Media</a></li>
                                    <li><a  (click)="filterByCategory('Location')">Location</a></li>
                                    <li><a  (click)="filterByCategory('Transport')">Transport</a></li>
                                </ul>
                            </div>
                            
                        </div>
                        <div class="col-md-8 col-lg-9">
                            <!-- <div class="row mb-3">
                                <div class="col-md-4">
                                    <div class="collections-item rounded overflow-hidden mb-4">
                                        <div class="collection-icon text-center p-5">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                                                    <path id="Icon" d="M35.2,52.8A11.2,11.2,0,1,1,46.4,64,11.2,11.2,0,0,1,35.2,52.8ZM0,20.8A20.8,20.8,0,1,1,20.8,41.6,20.8,20.8,0,0,1,0,20.8Zm48,6.4a8,8,0,1,1,8,8A8,8,0,0,1,48,27.2Z" fill="#fedfdf"/>
                                                </svg>                                                  
                                            </span>
                                        </div>
                                        <div class="collection-inner p-3">
                                            <h3 class="fs-6 fw-bold">Seamlessly schedule <span class="d-block">meetings</span></h3>
                                            <a href="#" class="view-link">View collection  <i class="fa-regular fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="collections-item rounded overflow-hidden mb-4">
                                        <div class="collection-icon text-center p-5 bg-yellow">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                                                    <path id="Icon" d="M22.9,62.438,1.562,41.1a5.331,5.331,0,0,1,0-7.542l32-32A5.307,5.307,0,0,1,37.333,0h16A10.668,10.668,0,0,1,64,10.667v16a5.311,5.311,0,0,1-1.562,3.771l-32,32a5.332,5.332,0,0,1-7.541,0Z" transform="translate(0)" fill="#fffcf0"/>
                                                  </svg>                                                                                                   
                                            </span>
                                        </div>
                                        <div class="collection-inner p-3">
                                            <h3 class="fs-6 fw-bold">Measure and improve 
                                                 <span class="d-block">your NPS</span></h3>
                                            <a href="#" class="view-link">View collection  <i class="fa-regular fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="collections-item rounded overflow-hidden mb-4">
                                        <div class="collection-icon text-center p-5 bg-purple">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                                                    <path id="Icon" d="M40.728,64a5.817,5.817,0,0,1-5.818-5.819V40.728a5.817,5.817,0,0,1,5.818-5.818H58.181A5.817,5.817,0,0,1,64,40.728V58.181A5.817,5.817,0,0,1,58.181,64ZM5.819,64A5.817,5.817,0,0,1,0,58.181V40.728a5.817,5.817,0,0,1,5.819-5.818H23.273a5.817,5.817,0,0,1,5.818,5.818V58.181A5.817,5.817,0,0,1,23.273,64ZM40.728,29.091a5.817,5.817,0,0,1-5.818-5.818V5.819A5.817,5.817,0,0,1,40.728,0H58.181A5.817,5.817,0,0,1,64,5.819V23.273a5.817,5.817,0,0,1-5.819,5.818Zm-34.909,0A5.817,5.817,0,0,1,0,23.273V5.819A5.817,5.817,0,0,1,5.819,0H23.273a5.817,5.817,0,0,1,5.818,5.819V23.273a5.816,5.816,0,0,1-5.818,5.818Z" fill="#f4f7fc"/>
                                                  </svg>                                                                                                   
                                            </span>
                                        </div>
                                        <div class="collection-inner p-3">
                                            <h3 class="fs-6 fw-bold">Supercharge your 
                                                <span class="d-block">customer data</span></h3>
                                            <a href="#" class="view-link">View collection  <i class="fa-regular fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <div class="d-flex align-items-center flex-wrap justify-content-between gap-3 mb-4">
                                        <h2 class="mb-0">Plugins</h2>
                                        <a href="#">See all  <i class="fa-regular fa-arrow-right"></i></a>
                                    </div>
                                </div>
                                <div class="col-md-4" *ngFor="let plugin of filteredPlugins">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-bluelight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 25 28">
                                                    <path id="Icon_Copy_6" data-name="Icon Copy 6" d="M0,6.364,12.5,0,25,6.364V21.636L12.5,28,0,21.636Z" fill="#f4f7fc"/>
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>{{ plugin.pluginName }}</h3>
                                            <p>{{ plugin.pluginDescription }}</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-redlight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30">
                                                    <path id="Icon_Copy_13" data-name="Icon Copy 13" d="M0,0H25V20L12.5,30,0,20Z" fill="#fedfdf"/>
                                                </svg>                                                           
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Application</h3>
                                            <p>Integrate directly with your account and make customer updates a breeze</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-greenlight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                    <path id="Icon_Copy_14" data-name="Icon Copy 14" d="M0,0H28V21.636H11.544L0,28Z" fill="#ebf9ea"/>
                                                </svg>                                                                                                   
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Integration</h3>
                                            <p>Improve and extend your experience right from your own UI</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-purplelight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20">
                                                    <path id="Icon_Copy_15" data-name="Icon Copy 15" d="M30,0,20.237,20H0L9.763,0Z" fill="#eeebff"/>
                                                  </svg>                                                                                                    
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Extension</h3>
                                            <p>The leading project management tool for developers  to build faster</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-skylight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20">
                                                    <path id="Icon_Copy_16" data-name="Icon Copy 16" d="M30,14.164A5.863,5.863,0,0,1,24.11,20H5.89A5.833,5.833,0,0,1,4.963,8.4a3.567,3.567,0,0,1-.057-.631,3.737,3.737,0,0,1,6.143-2.82C12.21,2.362,13.7,0,17.863,0c5.053,0,7.447,3.863,7.447,7.939,0,.171-.007.339-.017.507A5.851,5.851,0,0,1,30,14.164Z" fill="#edfafd"/>
                                                  </svg>                                                                                                    
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Plugin</h3>
                                            <p>With this Plugin you can collaborate with teammates at any time</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-skyblue-light">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                    <path id="Icon_Copy_17" data-name="Icon Copy 17" d="M0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15Z" fill="#e9eff6"/>
                                                  </svg>                                                                                                    
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Add On</h3>
                                            <p>With this Plugin you can collaborate with teammates at any time</p>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <!-- <div class="d-flex align-items-center flex-wrap justify-content-between gap-3 mb-4">
                                        <h2 class="mb-0">Essential Apps</h2>
                                        <a href="#">See all  <i class="fa-regular fa-arrow-right"></i></a>
                                    </div> -->
                                </div>
                                <!-- <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-bluelight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 25 28">
                                                    <path id="Icon_Copy_6" data-name="Icon Copy 6" d="M0,6.364,12.5,0,25,6.364V21.636L12.5,28,0,21.636Z" fill="#f4f7fc"/>
                                                </svg>                                                  
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Plugin</h3>
                                            <p>With this Plugin you can collaborate with teammates at any time</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-redlight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30">
                                                    <path id="Icon_Copy_13" data-name="Icon Copy 13" d="M0,0H25V20L12.5,30,0,20Z" fill="#fedfdf"/>
                                                </svg>                                                                                                 
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Application</h3>
                                            <p>Integrate directly with your account and make customer updates a breeze</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-greenlight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                    <path id="Icon_Copy_14" data-name="Icon Copy 14" d="M0,0H28V21.636H11.544L0,28Z" fill="#ebf9ea"/>
                                                </svg>                                                                                                   
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Integration</h3>
                                            <p>Improve and extend your experience right from your own UI</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-purplelight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20">
                                                    <path id="Icon_Copy_15" data-name="Icon Copy 15" d="M30,0,20.237,20H0L9.763,0Z" fill="#eeebff"/>
                                                  </svg>                                                                                                    
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Extension</h3>
                                            <p>The leading project management tool for developers  to build faster</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-skylight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20">
                                                    <path id="Icon_Copy_16" data-name="Icon Copy 16" d="M30,14.164A5.863,5.863,0,0,1,24.11,20H5.89A5.833,5.833,0,0,1,4.963,8.4a3.567,3.567,0,0,1-.057-.631,3.737,3.737,0,0,1,6.143-2.82C12.21,2.362,13.7,0,17.863,0c5.053,0,7.447,3.863,7.447,7.939,0,.171-.007.339-.017.507A5.851,5.851,0,0,1,30,14.164Z" fill="#edfafd"/>
                                                  </svg>                                                                                                    
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Plugin</h3>
                                            <p>With this Plugin you can collaborate with teammates at any time</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-skyblue-light">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                    <path id="Icon_Copy_17" data-name="Icon Copy 17" d="M0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15Z" fill="#e9eff6"/>
                                                  </svg>                                                                                                    
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Add On</h3>
                                            <p>With this Plugin you can collaborate with teammates at any time</p>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <!-- <div class="row mb-3">
                                <div class="col-md-12">
                                    <div class="d-flex align-items-center flex-wrap justify-content-between gap-3 mb-4">
                                        <h2 class="mb-0">My Apps</h2>
                                        <a href="#">See all  <i class="fa-regular fa-arrow-right"></i></a>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-bluelight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 25 28">
                                                    <path id="Icon_Copy_6" data-name="Icon Copy 6" d="M0,6.364,12.5,0,25,6.364V21.636L12.5,28,0,21.636Z" fill="#f4f7fc"/>
                                                </svg>                                                  
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Plugin</h3>
                                            <p>With this Plugin you can collaborate with teammates at any time</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-redlight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" viewBox="0 0 25 30">
                                                    <path id="Icon_Copy_13" data-name="Icon Copy 13" d="M0,0H25V20L12.5,30,0,20Z" fill="#fedfdf"/>
                                                </svg>                                                                                                 
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Application</h3>
                                            <p>Integrate directly with your account and make customer updates a breeze</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-greenlight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                                    <path id="Icon_Copy_14" data-name="Icon Copy 14" d="M0,0H28V21.636H11.544L0,28Z" fill="#ebf9ea"/>
                                                </svg>                                                                                                   
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Integration</h3>
                                            <p>Improve and extend your experience right from your own UI</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-purplelight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20">
                                                    <path id="Icon_Copy_15" data-name="Icon Copy 15" d="M30,0,20.237,20H0L9.763,0Z" fill="#eeebff"/>
                                                  </svg>                                                                                                    
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Extension</h3>
                                            <p>The leading project management tool for developers  to build faster</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-skylight">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20">
                                                    <path id="Icon_Copy_16" data-name="Icon Copy 16" d="M30,14.164A5.863,5.863,0,0,1,24.11,20H5.89A5.833,5.833,0,0,1,4.963,8.4a3.567,3.567,0,0,1-.057-.631,3.737,3.737,0,0,1,6.143-2.82C12.21,2.362,13.7,0,17.863,0c5.053,0,7.447,3.863,7.447,7.939,0,.171-.007.339-.017.507A5.851,5.851,0,0,1,30,14.164Z" fill="#edfafd"/>
                                                  </svg>                                                                                                    
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Plugin</h3>
                                            <p>With this Plugin you can collaborate with teammates at any time</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="popular-item rounded bg-white p-3 mb-4">
                                        <div class="popular-icon mb-3 bg-skyblue-light">
                                            <span class="svgicon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                    <path id="Icon_Copy_17" data-name="Icon Copy 17" d="M0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15Z" fill="#e9eff6"/>
                                                  </svg>                                                                                                    
                                            </span>
                                        </div>
                                        <div class="popular-inner">
                                            <h3>Add On</h3>
                                            <p>With this Plugin you can collaborate with teammates at any time</p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </section>

            <section class="app-store-sec py-5 bg-light-color">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="app-store text-center py-5">
                                <h2 class="fs-1">List Your App in our App Store</h2>
                                <p>Register as an app developer and submit your app easily with our App Store Developer Portal</p>
                                <a routerLink="/submit" class="btn btn-primary mt-4 btn-md fw-semibold py-2 px-3">Get Started as an App Developer <i class="fa-regular fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <!-- /. Main End -->
        <!-- footer start -->
        <footer class="site-footer bg-white py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <a href="#" class="mb-4 d-inline-block">
                            <img [src]="'assets/Images/logo.svg'" alt="Market Place" width="160" height="40" />
                        </a>
                    </div>
                    <div class="col-md-2">
                        <h3>Browse</h3>
                        <ul>
                            <li><a href="#">Most Popular</a></li>
                            <li><a href="#">Essential Apps</a></li>
                        </ul>
                    </div>
                    <div class="col-md-2">
                        <h3>Developers</h3>
                        <ul>
                            <li><a href="#">Documentation</a></li>
                            <li><a href="#">Getting Started </a></li>
                            <li><a href="#">API Reference
                            </a></li>
                            <li><a href="#">Sample Code</a></li>
                        </ul>
                    </div>
                    <div class="col-md-2">
                        <h3>Company</h3>
                        <ul>
                            <li><a href="#">Contact Us</a></li>
                            <li><a href="#">Blog</a></li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <ul class="social-icon">
                            <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <!-- /. footer End -->
    </div>
    <script src="js/custom.js"></script>
</body>
