import { Component } from '@angular/core';

@Component({
  selector: 'app-plugin-display',
  templateUrl: './plugin-display.component.html',
  styleUrls: ['./plugin-display.component.css']
})
export class PluginDisplayComponent {

}
