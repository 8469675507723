import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from '../cookie.service';
import { Router } from '@angular/router';
import { SessionService } from '../session.service';
import { ApiService } from 'src/api.service';

@Component({
  selector: 'app-plugin-manage',
  templateUrl: './plugin-manage.component.html',
  styleUrls: ['./plugin-manage.component.css']
})
export class PluginManageComponent {
  approvedPlugins: any[] = [];
  searchQuery: string = ''; 
  filteredPlugins: any[] = []; 
  selectedCategory: string | null = null; 


  constructor(private httpClient: HttpClient, private cookies:CookieService, private router: Router, private session:SessionService,private api:ApiService) { }

  ngOnInit(): void {
    // this.cookies.authservice();
    this.api.get('form-submissions').subscribe((data :any[])=> {
      console.log(data,'data');
      
      this.approvedPlugins = data.filter(plugin => plugin.pluginStatus === 'Approved');
      this.approvedPlugins.forEach(plugin => {
        console.log("Selected file:", plugin.selectedFile);
      });
      this.filteredPlugins = [...this.approvedPlugins]; 
    });
  }

  // filterPlugins() {
  //   this.filteredPlugins = this.approvedPlugins.filter((plugin) => {
  //     return plugin.pluginName.toLowerCase().includes(this.searchQuery.toLowerCase());
  //   });
  // }

  filterPlugins() {
    this.filteredPlugins = this.approvedPlugins.filter((plugin) => {
      return plugin.pluginName.toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  }

  filterByCategory(category: string) {
    this.selectedCategory = category;
    if (category === 'All') {
      this.filteredPlugins = this.approvedPlugins;
    } else {
      this.filteredPlugins = this.approvedPlugins.filter(plugin => plugin.pluginCategory === category);
    }
  }

  logOut() {
    this.session.logOut();
    this.cookies.deleteAuthCookies();
    window.location.href = `https://commonlogin.pbodev.info/login?frompage=${this.api.commanUrl}`;
  }
  


}
